<template>
  <div>
    <center>
      <h1>Welcome to Benchomatic</h1>
      <div id="firebaseui-auth-container"></div>
      <div id="loader">Loading...</div>
    </center>
  </div>
</template>

<script>
import { firebase, auth } from '@/services/firebase';
var firebaseui = require('firebaseui');
import 'firebaseui/dist/firebaseui.css';

var redirectTo = null;

var ui = new firebaseui.auth.AuthUI(auth);
var uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      // console.log(authResult, redirectUrl);
      // return true;
      console.log(redirectTo, ui, uiConfig);
      if (redirectTo != undefined && redirectTo != null && redirectTo.startsWith('/')) {
        window.location = redirectTo;
      } else {
        window.location = '/account';
      }
    },
    uiShown: function () {
      // The widget is rendered.
      // Hide the loader.
      document.getElementById('loader').style.display = 'none';
    },
  },
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  // signInFlow: 'popup',
  // signInSuccessUrl: '<url-to-redirect-to-on-success>',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID
  ],
  // // Terms of service url.
  // tosUrl: '<your-tos-url>',
  // // Privacy policy url.
  // privacyPolicyUrl: '<your-privacy-policy-url>'
};

export default {
  data() {
    return {};
  },
  created() {
    var self = this;
    console.log('redirectTo: ', self.$route.query.redirectTo);
    redirectTo = self.$route.query.redirectTo;
  },
  mounted() {
    ui.start('#firebaseui-auth-container', uiConfig);
  },
};
</script>

<style>
</style>