<template>
  <div>
    <div class="row m-5" v-if="!is_authenticated">
      <span v-html="authentication_status"></span>
    </div>
    <div v-if="is_authenticated">
      <nav class="navbar fixed-top navbar-light" :class="navbar_class">
        <div class="container-fluid">
          <a class="navbar-brand" href="#" @click.prevent="redirect_to_cro_home">
            <img :src="navbar_logo" width="200" alt class="d-inline-block align-top" />
          </a>
          <button class="btn btn-danger" type="submit" @click.prevent="signUserOut">SignOut</button>
        </div>
      </nav>

      <div class="container-fluid mt-4">
        <div class="row">
          <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
            <div class="position-sticky pt-3">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#" @click.prevent="move_back()">
                    <span data-feather="file"></span>
                    Recent Orders
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#" @click.prevent="move_forward()">
                    <span data-feather="file"></span>
                    Recent Products
                  </a>
                </li>
              </ul>
              <!-- 
              <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>Saved reports</span>
                <a class="link-secondary" href="#" aria-label="Add a new report">
                  <span data-feather="plus-circle"></span>
                </a>
              </h6>
              <ul class="nav flex-column mb-2">
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    <span data-feather="file-text"></span>
                    Current month
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    <span data-feather="file-text"></span>
                    Last quarter
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    <span data-feather="file-text"></span>
                    Social engagement
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    <span data-feather="file-text"></span>
                    Year-end sale
                  </a>
                </li>
              </ul>-->
            </div>
          </nav>

          <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4 pt-3 mb-5">
            <h2>Order ID 1001</h2>
            <div class="table-responsive">
              <table class="table table-striped table-sm">
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Selection</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Microtissue Type</td>
                    <td>Liver Microtissues</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Area of Focus</td>
                    <td>Liver Toxicology</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Prediction Applications</td>
                    <td>Tier 2: Long-term</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Microtissue Species</td>
                    <td>All (Human, Rat, Dog, Monkey)</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Total Drugs to Screen</td>
                    <td>x5</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Total Microtissues to Screen (per drug)</td>
                    <td>x10</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Readouts</td>
                    <td>3 Readouts</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>
                      <button type="button" class="btn btn-danger">
                        <strong>ACTION REQUIRED</strong>
                      </button>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Progress</td>
                    <td>
                      0%
                      <br />
                      <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- <a class="btn btn-dark" href="/dashboard/orders_2.html" role="button">Create an Update</a> -->
            <br />
            <h3 class="mt-3">Changes / Amendments</h3>
            <div class="form-floating">
              <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
              <label for="floatingTextarea2">We recommend screening against at least 5 microtissues per drug to increase statistical power.</label>
            </div>
            <br />
            <button type="button" class="btn btn-success">Accept Changes</button>&nbsp;
            <button type="button" class="btn btn-primary">Reply</button>

            <br />
            <br />
            <br />
            <h3 class="mt-3">Additional Information Needed</h3>
            <p>Please provide the following information:</p>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value id="flexCheckDefault" />
              <label class="form-check-label" for="flexCheckDefault">
                Submit the
                <a href="#">Compounds Form</a>.
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value id="flexCheckChecked" />
              <label class="form-check-label" for="flexCheckChecked">
                Sign the MSA
                <a href="#">Agreement</a>.
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value id="flexCheckChecked" />
              <label class="form-check-label" for="flexCheckChecked">
                Pay the
                <a href="#">Invoice</a>.
              </label>
            </div>
            <br />
            <button type="button" class="btn btn-primary">Submit</button>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExperimentNode from '@/components/ExperimentNode.vue';
import { auth } from '@/services/firebase';
import { appAlertBuilder } from '@/helpers/app.js';

var main_endpoint = process.env.VUE_APP_MAIN_ENDPOINT;
var debug_mode = process.env.VUE_APP_DEBUG_MODE == 'true';

var default_status = '<div class="spinner-border" role="status"></div>';

export default {
  components: {
    ExperimentNode,
  },
  data() {
    return {
      is_authenticated: false,
      signout_redirect: null,
      authentication_status: default_status,
      authed_user: null,

      cro_id: null,
      navbar_class: '',
      navbar_logo: '',
    };
  },
  methods: {
    move_back: function () {
      window.location = '/' + this.cro_id + '/account/1001/step1';
    },
    move_forward: function () {
      window.location = '/' + this.cro_id + '/account/1001/step3';
    },

    create_bop: function () {
      var self = this;
      self.bop_in_progress = true;
      self.bop_status = null;

      fetch(main_endpoint + '/workflows', {
        method: 'POST',
        body: JSON.stringify({
          user_id: self.authed_user.uid,
          user_data: {
            email: self.authed_user.email,
            uid: self.authed_user.uid,
            displayName: self.authed_user.displayName,
          },
          cro_id: self.cro_id,
          data: self.get_final_selection(),
        }),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (response) {
          console.log(response);
          let success = response.response.success;
          let message = response.response.message;
          let data = response.response.data;

          if (!success) {
            throw new Error(message);
          } else {
            self.bop_in_progress = false;
            self.bop_status = 'Request Submitted!';
            // self.bop_status = 'View it <a href="/' + self.cro_id + '/account">here</a>';
          }
        })
        .catch(function (error) {
          console.log(error);
          self.bop_in_progress = false;
          self.bop_status = appAlertBuilder(error, 'danger');
        });
    },
    redirect_to_cro_home: function () {
      this.$router.push('/' + this.cro_id);
    },
    load_experiment_data: function () {
      var self = this;

      // if(self.cro_id == "cellink"){
      //   self.navbar_class = "bg-cellink";
      //   self.navbar_logo = "/img/cellink/logo.png";
      // }else if(self.cro_id == "insphero"){
      //   self.navbar_class = "bg-insphero";
      //   self.navbar_logo = "/img/insphero/logo.png";
      // }else if(self.cro_id == "stemcell"){
      //   self.navbar_class = "bg-stemcell";
      //   self.navbar_logo = "/img/stemcell/logo.png";
      // }else{
      // }

      self.navbar_class = 'bg-' + self.cro_id;
      self.navbar_logo = '/img/' + self.cro_id + '/logo.png';
    },
    signUserOut: function () {
      var self = this;
      self.signout_redirect = '/' + self.cro_id;
      auth.signOut();
    },
  },
  created() {
    var self = this;
    self.cro_id = self.$route.params.cro_id;

    self.is_authenticated = false;
    auth.onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        console.log('is signed', user.email, user.displayName);
        self.is_authenticated = true;
        self.authentication_status = 'Authenticated!';
        self.authed_user = user;
      } else {
        // User is NOT signed in.
        console.log('NOT signed', user, self.signout_redirect);
        self.is_authenticated = false;
        if (self.signout_redirect == null) {
          self.$router.push('/auth?redirectTo=/' + self.cro_id + '/admin/');
        } else {
          self.$router.push('/' + self.cro_id);
        }
      }
    });

    // self.cleanup_final_selection();
    self.load_experiment_data();

    // for (let index = 0; index < self.nodes.length; index++) {
    //   const tasks_in_node = self.nodes[index].data.length;
    //   self.total_tasks += tasks_in_node;
    // }
    // console.log("Found " + self.total_tasks + " tasks in total.");

    // for quick testing
    // self.create_fake_final_selection();
  },
};
</script>

<style scoped>
.bg-cellink {
  background-color: rgba(21, 27, 150, 1);
}
.bg-insphero {
  background-color: rgba(115, 142, 32, 1);
}
.bg-stemcell {
  background-color: rgba(247, 247, 245, 1);
}
.bg-cnbio {
  background-color: rgba(247, 247, 245, 1);
}
.bg-cytiva {
  background-color: rgba(0, 136, 112, 1);
}

.bg-exampleco {
  background-color: rgba(21, 27, 150, 1);
}

.bg-chimebiologics {
  background-color: rgba(247, 247, 245, 1);
}

.bg-milaboratory {
  background-color: rgba(247, 247, 245, 1);
}

.bg-fujifilm {
  /* background-color: rgba(15,175,128, 1); */
  background-color: rgba(247, 247, 245, 1);
}
</style>

