<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>-->
  <router-view />
</template>

<script>
export default {
  created() {
    // console.log(process.env.VUE_APP_NAME + "@" + process.env.VUE_APP_VERSION + "@" + process.env.VUE_APP_CRO_ID + "@" + process.env.VUE_APP_GIT_COMMIT);
    console.log(process.env.VUE_APP_NAME + '@' + process.env.VUE_APP_VERSION + '@' + process.env.VUE_APP_GIT_COMMIT);
  },
};
</script>

<style>
body {
  padding-top: 60px;
}
</style>
