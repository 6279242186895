<template>
  <div>
    
    <!-- <nav class="navbar fixed-top navbar-light" style="background-color: #8cb802;"> -->
    <nav class="navbar fixed-top navbar-light bg-nav">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="img/milaboratory/logo.png" width="200" alt="" class="d-inline-block align-top"></a>
        <form class="d-flex">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"  size="50"
            v-model="search_query">
          <button class="btn btn-primary bg-custom" type="submit"
            @click.prevent="search">Search</button>
        </form>
      </div>
    </nav>


    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            Featured
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/milaboratory/tumor.png" class="figure-img img-fluid rounded" alt="Generic" style="padding: 30px">
                <figcaption class="figure-caption text-center">Tumor</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D Tumor Microtissue Model</h5>
                <p class="card-text text-start">Our 3D Tumor Microtissues are designed to simplify and accelerate oncology drug efficacy testing. Our oncology experts have identified optimal culture conditions for large-scale, reproducible production of 3D printed spheroid models that better reflect complex tumor biology. </p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Leverage physiologically relevant 3D tumor co-cultures composed of tumor and stromal components. </li>
                    <li class="text-start">Assess single agent or combinatorial efficacy of small molecule, biological, ADC, or immunomodulatory drugs using 3D optimized endpoints.</li>
                    <li class="text-start">Quality controlled pre-validated models that optimize your staff resources and ensure robust, reproducible results.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Tumor</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Open plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">6-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">12-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Drug Screening</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">BIO X6</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">I-DOT</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">CELLCYTE X</span>&nbsp;
                </p>
                <!-- <p class="text-center">
                  <a href="#" class="btn btn-primary" 
                    @click.prevent="redirect_to_exp_builder('3d-tumor-milaboratory-j4sys4')"
                    >Order Now</a>
                </p> -->

                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('generic_2')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div> 

      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/milaboratory/liver.png" class="figure-img img-fluid rounded" alt="Generic" style="padding: 30px">
                <figcaption class="figure-caption text-center">Liver</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D Liver Microtissue Model</h5>
                <p class="card-text text-start">Hepatotoxicity Testing assesses the effect of compounds on cellular viability in our standardized Human Liver Microtissues, developed from primary human hepatocytes in co-culture with Kupffer cells and liver endothelial cells (LECs). Cell viability dose-response curves are determined following a repeat dose exposure to compounds. </p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Predict Drug-Induced Liver Injury (DILI) with twice the sensitivity of standard 2D Primary Human Hepatocytes (PHH) assays without sacrificing specificity.</li>
                    <li class="text-start">Test in our mechanistically relevant, extensively validated 3D liver model, developed using pooled multi-donor PHH in co-culture with Kupffer cells and liver endothelial cells.</li>
                    <li class="text-start">Customize your study with additional functional and mechanistic endpoints as needed.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Liver</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Open plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">6-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Drug Screening</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Toxicology Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">BIO X6</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">I-DOT</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">CELLCYTE X</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('generic_2')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   
    </div> 


    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/milaboratory/pancreas.png" class="figure-img img-fluid rounded" alt="Generic" style="padding: 30px">
                <figcaption class="figure-caption text-center">Pancreas</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D Islet Microtissue Model </h5>
                <p class="card-text text-start">Human Islet Microtissues are a standardized alternative to primary human pancreatic islets for reproducible, reliable, and more efficient research. </p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Expand your GSIS assay window and improve sensitivity to insulin secretagogues with a long-lived, robust model persisting more than 28 days in culture.</li>
                    <li class="text-start">Eliminate islet pooling and data normalization with islet microtissues homogeneous in size and cellular composition.</li>
                    <li class="text-start">Increase efficiency 6-fold by eliminating the need to handpick islets with microtissues available on demand.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Diabetes</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Endocrine</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Islet</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Β-cell</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Open plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">6-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Drug Screening</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Toxicology Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">BIO X6</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">I-DOT</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">CELLCYTE X</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('generic3_3')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   

      <!-- missing second -->
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      search_query: null,
    };
  },
  methods: {
    redirect_to_exp_builder: function (expid) {
      this.$router.push(this.$route.path + '/design/' + expid);
      // window.location = "/" + expid;
      // alert("Not connected yet. You will be redirected to the experiment builder.");
    },
    search: function () {
      alert('Not connected yet. You will search for [' + this.search_query + ']');
    },
  },
};
</script>

<style scoped>
.bg-nav {
  background-color: rgba(247, 247, 245, 1);
}
.bg-custom {
  /* background-color: rgba(21, 27, 150, 1); */
  background-color: rgba(238, 131, 0, 1);
}

/* .bg-nav{
  background-color: rgba(247, 247, 245, 1);
}
.bg-custom{
  background-color: rgba(238, 131, 0, 1);
} */
</style>

