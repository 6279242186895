import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// VUE select v2.X only, not yet 3
// import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';

// SENTRY
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserSecret,
  faVial,
  faSyringe,
  faFlask,
  faMicroscope,
  faRuler,
  faBiohazard,
  faAtom,
  faHeartbeat,
  faEye,
  faChartBar,
  faDna,
  faTh,
  faShapes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(
  faUserSecret,
  faVial,
  faSyringe,
  faFlask,
  faMicroscope,
  faRuler,
  faBiohazard,
  faAtom,
  faHeartbeat,
  faEye,
  faChartBar,
  faDna,
  faTh,
  faShapes
);

const app = createApp(App);

Sentry.init({
  app,
  release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}@${process.env.VUE_APP_GIT_COMMIT}`,
  dsn: 'https://613bfddcc20d4c01a0258451f40fbf77@o1338482.ingest.sentry.io/6609480',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['*'],
    }),
  ],
  tracesSampleRate: 1,
  logErrors: true,
  trackComponents: true,
  hooks: ['activate', 'create', 'destroy', 'mount', 'unmount', 'update'],
});

// app.component('v-select', vSelect)
app.component('font-awesome-icon', FontAwesomeIcon);

app
  .use(store)
  .use(router)
  .mount('#app');
