<template>
  <div>
    
    <!-- <nav class="navbar fixed-top navbar-light" style="background-color: #8cb802;"> -->
    <nav class="navbar fixed-top navbar-light bg-nav">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="img/stemcell/logo.png" width="200" alt="" class="d-inline-block align-top"></a>
        <form class="d-flex">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"  size="50"
            v-model="search_query">
          <button class="btn btn-primary bg-custom" type="submit"
            @click.prevent="search">Search</button>
        </form>
      </div>
    </nav>


    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            Featured
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/stemcell/hematopoetic.png" class="figure-img img-fluid rounded" alt="StemCell" style="padding: 30px">
                <figcaption class="figure-caption text-center">Hematopoietic stem cells</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">HemaTox™ - Liquid Culture-Based Hematotoxicity Assays</h5>
                <p class="card-text text-start">HemaTox™ assays can assess the toxicity of drugs on the growth and lineage-specific differentiation of human CD34+ hematopoietic stem and progenitor cells (HSPCs) into one of three specific progenitor cell lineages (erythroid, myeloid, or megakaryocyte).</p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Assesses both proliferation and differentiation of erythroid, myeloid and megakaryocyte progenitors in &lt; 10 days.</li>
                    <li class="text-start">Allows high-throughput testing of compounds in 96-well format.</li>
                    <li class="text-start">Exhibits correlation with the CFU assay for toxicity levels of a wide range of compounds.</li>
                    <li class="text-start">Allows high flexibility as test compounds may be added to the culture at different time points, allowing the effects on progenitor cells at different stages of differentiation to be examined.</li>
                    <li class="text-start">Provides improved sensitivity in the evaluation of anti-proliferative effects.</li>
                    <li class="text-start">Provides high reproducibility with pre-qualified primary stem cells from human cord blood or bone marrow.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Hematopoietic Stem Cells</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Erythroid</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Myeloid</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Megakaryocyte</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Blood</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Toxicology</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Drug Screening</span>&nbsp;
                </p>
                <!-- <p class="text-center">
                  <a href="#" class="btn btn-primary" 
                    @click.prevent="redirect_to_exp_builder('liquid-culture-based-hematotoxicity-assay-47cny58')"
                    >Order Now</a>
                </p> -->
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('stemcell_dili')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div> 

      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/stemcell/mesenchymal.png" class="figure-img img-fluid rounded" alt="StemCell" style="padding: 30px">
                <figcaption class="figure-caption text-center">Mesenchymal stem cells</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">Mesenchymal Toxicity Assessment</h5>
                <p class="card-text text-start">Mesenchymal stem cells (MSCs), under the appropriate conditions, can differentiate into cells that make up adipose tissue, cartilage, bone and muscle. Using the colony-forming cell-fibroblast (CFU-F) assay, drug candidates destined for the tissue engineering market can be assessed for stimulatory or inhibitory effects on mesenchymal stem and progenitor cells, as well as for effects on the differentiation potential of these cells. The effects of compounds on colony size and density can also be evaluated.</p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Measures the effects of a test article on progenitor frequency.</li>
                    <li class="text-start">Assesses proliferative or expansion potential of progenitors (size and morphology of colonies).</li>
                    <li class="text-start">Quantitates mesenchymal progenitors in bone marrow.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Mesenchymal stem cells</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Bone marrow</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Adipose tissue</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Cartilage</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Bone and muscle</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Toxicology</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Drug Screening</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('stemcell_dili')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   
    </div> 

    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/stemcell/intestinal.png" class="figure-img img-fluid rounded" alt="StemCell" style="padding: 30px">
                <figcaption class="figure-caption text-center">Intestinal cells</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">Intestinal Organoid Models for Toxicity Assessment</h5>
                <p class="card-text text-start">The physiological relevance of cell-based assays can potentially be increased through the use of 3D culture systems, bridging the gap between high-throughput in vitro screening methods and large in vivo studies during drug development. Organoids generated from the intestinal epithelium recapitulate numerous features of the adult intestine in vivo, including self-renewal and differentiation pathways, cell types present and cellular organization within the epithelium. Together these characteristics create a culture system that is a powerful tool for investigating the potential toxicity of candidate therapeutics to the intestinal epithelium.</p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Contract Assay Services offers an in vitro mouse intestinal organoid-based assay to assess the effect of candidate therapeutics on cell viability via measurement of intracellular ATP.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Intestinal cells</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Organoids</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Toxicology</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Drug Screening</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('stemcell_dili')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   

      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/stemcell/tumor.png" class="figure-img img-fluid rounded" alt="StemCell" style="padding: 30px">
                <figcaption class="figure-caption text-center">Tumor Model</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D Tumor Microtissue Model</h5>
                <p class="card-text text-start">Our 3D Tumor Microtissues are designed to simplify and accelerate oncology drug efficacy testing. Our oncology experts have identified optimal culture conditions for large-scale, reproducible production of 3D printed spheroid models that better reflect complex tumor biology. </p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Leverage physiologically relevant 3D tumor co-cultures composed of tumor and stromal components.</li>
                    <li class="text-start">Assess single agent or combinatorial efficacy of small molecule, biological, ADC, or immunomodulatory drugs using 3D optimized endpoints.</li>
                    <li class="text-start">Quality controlled pre-validated models that optimize your staff resources and ensure robust, reproducible results.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Tumor</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Drug Screening</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('stemcell_dili')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   
    </div> 

    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/stemcell/liver.png" class="figure-img img-fluid rounded" alt="StemCell" style="padding: 30px">
                <figcaption class="figure-caption text-center">Liver Model</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D Liver Microtissue Model</h5>
                <p class="card-text text-start">Hepatotoxicity Testing assesses the effect of compounds on cellular viability in our standardized Human Liver Microtissues, developed from primary human hepatocytes in co-culture with Kupffer cells and liver endothelial cells (LECs). Cell viability dose-response curves are determined following a repeat dose exposure to compounds.</p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Predict Drug-Induced Liver Injury (DILI) with twice the sensitivity of standard 2D Primary Human Hepatocytes (PHH) assays without sacrificing specificity.</li>
                    <li class="text-start">Test in our mechanistically relevant, extensively validated 3D liver model, developed using pooled multi-donor PHH in co-culture with Kupffer cells and liver endothelial cells.</li>
                    <li class="text-start">Customize your study with additional functional and mechanistic endpoints as needed.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Liver</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Drug Screening</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Toxicology Model</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('stemcell_dili')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   
      
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/stemcell/pancreas.png" class="figure-img img-fluid rounded" alt="StemCell" style="padding: 30px">
                <figcaption class="figure-caption text-center">Pancreas Model</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D Islet Microtissue Model</h5>
                <p class="card-text text-start">Human Islet Microtissues are a standardized alternative to primary human pancreatic islets for reproducible, reliable, and more efficient research.</p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Expand your GSIS assay window and improve sensitivity to insulin secretagogues with a long-lived, robust model persisting more than 28 days in culture.</li>
                    <li class="text-start">Eliminate islet pooling and data normalization with islet microtissues homogeneous in size and cellular composition.</li>
                    <li class="text-start">Increase efficiency 6-fold by eliminating the need to handpick islets with microtissues available on demand.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Diabetes</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Endocrine</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Islet</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Β-cell</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Drug Screening</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Toxicology Model</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('stemcell_dili')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   
    </div> 


  </div>
</template>

<script>
export default {
  data() {
    return {
      search_query: null,
    };
  },
  methods: {
    redirect_to_exp_builder: function (expid) {
      this.$router.push(this.$route.path + '/design/' + expid);
      // window.location = "/" + expid;
      // alert("Not connected yet. You will be redirected to the experiment builder.");
    },
    search: function () {
      alert('Not connected yet. You will search for [' + this.search_query + ']');
    },
  },
};
</script>

<style scoped>
.bg-nav {
  background-color: rgba(247, 247, 245, 1);
}
.bg-custom {
  background-color: rgba(238, 131, 0, 1);
}
</style>

