
let demo_prefix = "stemcell";
let experiment_header = "HemaTox™ - Liquid Culture-Based Hematotoxicity Assays";

let nodes= [
    { 
        id: 'node-1', 
        title: 'Tissue Models', 
        icon: ['fas', 'heartbeat'],
        data: [
            {
                type: "select",
                options: [
                    {"task": "1", "value": demo_prefix + "-1a", "label": "HemaTox™ - Liquid Culture-Based Hematotoxicity Assays", "cost": 750, "duration": 0},
                    {"task": "1", "value": demo_prefix + "-1b",  "label": "Mesenchymal Toxicity Assessment",  "cost": 850, "duration": 0},
                    {"task": "1", "value": demo_prefix + "-1c", "label": "Intestinal Organoid Models for Toxicity Assessment", "cost": 650, "duration": 0},
                    {"task": "1", "value": demo_prefix + "-1d", "label": "Liver Microtissues for Disease & Toxicology Studies", "cost": 750, "duration": 0},
                    {"task": "1", "value": demo_prefix + "-1e", "label": "Islet Microtissues for Metabolic Disease Studies", "cost": 850, "duration": 0},
                    {"task": "1", "value": demo_prefix + "-1f", "label": "Tumor Microtissues for Oncology Studies", "cost": 650, "duration": 0},
                ],
            },
        ]
    },
    { 
        id: 'node-2', 
        title: 'Area of Focus', 
        icon: ['fas', 'eye'],
        data: [
            {
                type: "select",
                options: [
                    {"task": "2", "value": demo_prefix + "-2a", "label": "Toxicology", "cost": 0, "duration": 0},
                    {"task": "2", "value": demo_prefix + "-2b", "label": "Disease", "cost": 0, "duration": 0},
                ],
            },
        ]
    },
    { 
        id: 'node-3', 
        title: 'Prediction Applications', 
        icon: ['fas', 'chart-bar'],
        data: [
            {
                type: "select",
                options: [
                    {"task": "3", "value": demo_prefix + "-3a", "label": "Short-Term", "cost": 750, "duration": 7},
                    {"task": "3", "value": demo_prefix + "-3b", "label": "Long-term", "cost": 1500, "duration": 14},
                    {"task": "3", "value": demo_prefix + "-3c", "label": "Translational Toxicity", "cost": 1500, "duration": 14},
                ],
            },
        ]
    },
    { 
        id: 'node-4', 
        title: 'Tissue Species', 
        icon: ['fas', 'dna'],
        data: [
            {
                type: "select",
                options: [
                    {"task": "4", "value": demo_prefix + "-4a", "label": "Human", "cost": 6, "duration": 0},
                    {"task": "4", "value": demo_prefix + "-4b", "label": "Rat", "cost": 3, "duration": 0},
                    {"task": "4", "value": demo_prefix + "-4c", "label": "Dog", "cost": 4, "duration": 0},
                    {"task": "4", "value": demo_prefix + "-4d", "label": "Monkey", "cost": 5, "duration": 0},
                    {"task": "4", "value": demo_prefix + "-4e", "label": "All", "cost": 15, "duration": 0},
                ],
            },
        ]
    },
    { 
        id: 'node-5', 
        title: 'Total Drugs to Screen', 
        icon: ['fas', 'vial'],
        data: [
            {
                type: "select",
                options: [
                    {"task": "5", "value": "1", "label": "x1", "cost": 0, "duration": 0},
                    {"task": "5", "value": "5", "label": "x5", "cost": 0, "duration": 0},
                    {"task": "5", "value": "10", "label": "x10", "cost": 0, "duration": 0},
                    {"task": "5", "value": "25", "label": "x25", "cost": 0, "duration": 0},
                    {"task": "5", "value": "50", "label": "x50", "cost": 0, "duration": 0},
                    {"task": "5", "value": "100", "label": "x100", "cost": 0, "duration": 0},
                    {"task": "5", "value": "250", "label": "x250", "cost": 0, "duration": 0},
                    {"task": "5", "value": "500", "label": "x500", "cost": 0, "duration": 0},
                ],
            },
        ]
    },
    { 
        id: 'node-6', 
        title: 'Total Wells to Screen (per drug)', 
        icon: ['fas', 'th'],
        data: [
            {
                type: "select",
                options: [
                    {"task": "6", "value": "3", "label": "x3", "cost": 0, "duration": 0},
                    {"task": "6", "value": "4", "label": "x4", "cost": 0, "duration": 0},
                    {"task": "6", "value": "5", "label": "x5", "cost": 0, "duration": 0},
                    {"task": "6", "value": "10", "label": "x10", "cost": 0, "duration": 0},
                    {"task": "6", "value": "15", "label": "x15", "cost": 0, "duration": 0},
                ],
            },
        ]
    },
    { 
        id: 'node-7', 
        title: 'Readouts', 
        icon: ['fas', 'ruler'],
        data: [
            {
                type: "select",
                options: [
                    {"task": "7", "value": demo_prefix + "-7a", "label": "1 Readout", "cost": 5, "duration": 0},
                    {"task": "7", "value": demo_prefix + "-7b", "label": "2 Readouts", "cost": 10, "duration": 0},
                    {"task": "7", "value": demo_prefix + "-7c", "label": "3 Readouts", "cost": 15, "duration": 0},
                    {"task": "7", "value": demo_prefix + "-7d", "label": "4 Readouts", "cost": 20, "duration": 0},
                    {"task": "7", "value": demo_prefix + "-7e", "label": "5 Readouts", "cost": 25, "duration": 0},
                ],
            },
        ]
    },
];

let header_stemcell_1 = experiment_header;
let nodes_stemcell_1 = nodes;

export {header_stemcell_1, nodes_stemcell_1}
