<template>

  <div>
    
    <!-- <nav class="navbar fixed-top navbar-light" style="background-color: #8cb802;"> -->
    <nav class="navbar fixed-top navbar-light bg-custom">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="img/cellink/logo.png" width="200" alt="" class="d-inline-block align-top"></a>
        <form class="d-flex">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"  size="50"
            v-model="search_query">
          <button class="btn btn-success" type="submit"
            @click.prevent="search">Search</button>
        </form>
      </div>
    </nav>



    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            Featured
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/cellink/tumor.png" class="figure-img img-fluid rounded" alt="Cellink" style="padding: 30px">
                <figcaption class="figure-caption text-center">Tumor</figcaption>
              </figure>
              <!-- <img src="img/tumor.png" alt="Cellink" style="padding: 30px"> -->
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D Tumor Microtissue Model</h5>
                <p class="card-text text-start">Our 3D Tumor Microtissues are designed to simplify and accelerate oncology drug efficacy testing. Our oncology experts have identified optimal culture conditions for large-scale, reproducible production of 3D printed spheroid models that better reflect complex tumor biology.</p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Leverage physiologically relevant 3D tumor co-cultures composed of tumor and stromal components.</li>
                    <li class="text-start">Assess single agent or combinatorial efficacy of small molecule, biological, ADC, or immunomodulatory drugs using 3D optimized endpoints.</li>
                    <li class="text-start">Quality controlled pre-validated models that optimize your staff resources and ensure robust, reproducible results.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Tumor</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Open plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">6-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">12-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Drug Screening</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">BIO X6</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">I-DOT</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">CELLCYTE X</span>&nbsp;
                </p>
                <!-- <p class="text-center">
                  <a href="#" class="btn btn-warning" 
                    @click.prevent="redirect_to_exp_builder('drug-screening-3d-tumor-microtissues-6f3xt64')"
                    >Order Now</a>
                </p> -->
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('cellink_3dliver')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div> 

      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            Featured
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/cellink/liver.png" class="figure-img img-fluid rounded" alt="Cellink" style="padding: 30px">
                <figcaption class="figure-caption text-center">Liver</figcaption>
              </figure>
              <!-- <img src="img/cellink.png" width="200"  alt="Cellink" style="padding: 30px"> -->
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D Liver Microtissue Model</h5>
                <p class="card-text text-start">Hepatotoxicity Testing assesses the effect of compounds on cellular viability in our standardized Human Liver Microtissues, developed from primary human hepatocytes in co-culture with Kupffer cells and liver endothelial cells (LECs). Cell viability dose-response curves are determined following a repeat dose exposure to compounds.</p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Predict Drug-Induced Liver Injury (DILI) with twice the sensitivity of standard 2D Primary Human Hepatocytes (PHH) assays without sacrificing specificity.</li>
                    <li class="text-start">Test in our mechanistically relevant, extensively validated 3D liver model, developed using pooled multi-donor PHH in co-culture with Kupffer cells and liver endothelial cells.</li>
                    <li class="text-start">Customize your study with additional functional and mechanistic endpoints as needed.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Liver</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Open plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">6-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Drug Screening</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Toxicology Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">BIO X6</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">I-DOT</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">CELLCYTE X</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('cellink_3dliver')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>  
    </div> 

    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            Featured
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/cellink/pancreas.png" class="figure-img img-fluid rounded" alt="Cellink" style="padding: 30px">
                <figcaption class="figure-caption text-center">Pancreas</figcaption>
              </figure>
              <!-- <img src="img/cellink.png" width="200"  alt="Cellink" style="padding: 30px"> -->
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D Islet Microtissue Model</h5>
                <p class="card-text text-start">Human Islet Microtissues are a standardized alternative to primary human pancreatic islets for reproducible, reliable, and more efficient research.</p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Expand your GSIS assay window and improve sensitivity to insulin secretagogues with a long-lived, robust model persisting more than 28 days in culture.</li>
                    <li class="text-start">Eliminate islet pooling and data normalization with islet microtissues homogeneous in size and cellular composition.</li>
                    <li class="text-start">Increase efficiency 6-fold by eliminating the need to handpick islets with microtissues available on demand.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Diabetes</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Endocrine</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Islet</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">β-cell</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Open plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">6-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Drug Screening</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Toxicology Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">BIO X6</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">I-DOT</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">CELLCYTE X</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('cellink_3dpancreas')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div> 

      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            Featured
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/cellink/bio_x.png" class="figure-img img-fluid rounded" alt="Cellink" style="padding: 30px">
                <figcaption class="figure-caption text-center">BIO X</figcaption>
              </figure>
              <!-- <img src="img/cellink.png" width="200"  alt="Cellink" style="padding: 30px"> -->
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">BIO X<sup>™</sup></h5>
                <p class="card-text text-start">The BIO X’s compatibility with virtually any material makes it the bioprinter of choice for industry leaders at the forefront of today’s biggest scientific breakthroughs. Whether you are automating 3D cell cultures, developing complex tissue constructs or testing new drug compounds, the BIO X has the advanced functionality and versatility to streamline workflows in a wide range of application areas.</p>
                <!-- <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Expand your GSIS assay window and improve sensitivity to insulin secretagogues with a long-lived, robust model persisting more than 28 days in culture.</li>
                    <li class="text-start">Eliminate islet pooling and data normalization with islet microtissues homogeneous in size and cellular composition.</li>
                    <li class="text-start">Increase efficiency 6-fold by eliminating the need to handpick islets with microtissues available on demand.</li>
                  </ul>  
                </p> -->
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">3D printer</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">BIO X</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('cellink_biox')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div> 
    </div> 

    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            Featured
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/cellink/bio_x6.jpg" class="figure-img img-fluid rounded" alt="Cellink" style="padding: 30px">
                <figcaption class="figure-caption text-center">BIO X6</figcaption>
              </figure>
              <!-- <img src="img/cellink.png" width="200"  alt="Cellink" style="padding: 30px"> -->
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">BIO X6<sup>™</sup></h5>
                <p class="card-text text-start">Control every aspect of your print from the intuitive and easy-to-use DNA Studio software on your tablet or laptop. Whether it is multilayer printing, layer-by-layer crosslinking, custom material definition, or even protocol saving, the BIO X6 software has it all.</p>
                <!-- <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Expand your GSIS assay window and improve sensitivity to insulin secretagogues with a long-lived, robust model persisting more than 28 days in culture.</li>
                    <li class="text-start">Eliminate islet pooling and data normalization with islet microtissues homogeneous in size and cellular composition.</li>
                    <li class="text-start">Increase efficiency 6-fold by eliminating the need to handpick islets with microtissues available on demand.</li>
                  </ul>  
                </p> -->
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">3D printer</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">BIO X6</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('cellink_biox6')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div> 

      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            Featured
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/cellink/lumen_x.png" class="figure-img img-fluid rounded" alt="Cellink" style="padding: 30px">
                <figcaption class="figure-caption text-center">LUMEN X™</figcaption>
              </figure>
              <!-- <img src="img/cellink.png" width="200"  alt="Cellink" style="padding: 30px"> -->
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">LUMEN X<sup>™</sup></h5>
                <p class="card-text text-start">The LUMEN X divides 3D models into stacks of horizontal layers in the form of black and white image files. Using an industrial-grade visible-light projector, each image is projected onto a droplet of liquid PhotoInk™ on a polydimethylsiloxane (PDMS) vat. The illuminated regions react and solidify, then the build platform moves the cured layer up and out of the way, so that more liquid PhotoInk can be cured with the next image.</p>
                <!-- <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Expand your GSIS assay window and improve sensitivity to insulin secretagogues with a long-lived, robust model persisting more than 28 days in culture.</li>
                    <li class="text-start">Eliminate islet pooling and data normalization with islet microtissues homogeneous in size and cellular composition.</li>
                    <li class="text-start">Increase efficiency 6-fold by eliminating the need to handpick islets with microtissues available on demand.</li>
                  </ul>  
                </p> -->
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">3D printer</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">LUMEN X</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('cellink_lumenx')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div> 
    </div> 

    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            Featured
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/cellink/inkredible.jpg" class="figure-img img-fluid rounded" alt="Cellink" style="padding: 30px">
                <figcaption class="figure-caption text-center">INKREDIBLE+™</figcaption>
              </figure>
              <!-- <img src="img/cellink.png" width="200"  alt="Cellink" style="padding: 30px"> -->
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">INKREDIBLE+<sup>™</sup></h5>
                <p class="card-text text-start">Robust, reliable and small enough to fit on your benchtop, the powerful INKREDIBLE+ 3D bioprinter is perfect for advanced research applications. The technology offers a pneumatic-based extrusion system with dual print heads and built-in UV LED curing that allows for quick and easy bioprinting of living tissues.</p>
                <!-- <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Expand your GSIS assay window and improve sensitivity to insulin secretagogues with a long-lived, robust model persisting more than 28 days in culture.</li>
                    <li class="text-start">Eliminate islet pooling and data normalization with islet microtissues homogeneous in size and cellular composition.</li>
                    <li class="text-start">Increase efficiency 6-fold by eliminating the need to handpick islets with microtissues available on demand.</li>
                  </ul>  
                </p> -->
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">3D printer</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">INKREDIBLE+</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('cellink_inkredible')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div> 

      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            Featured
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/cellink/holograph_x.jpg" class="figure-img img-fluid rounded" alt="Cellink" style="padding: 30px">
                <figcaption class="figure-caption text-center">Holograph X™</figcaption>
              </figure>
              <!-- <img src="img/cellink.png" width="200"  alt="Cellink" style="padding: 30px"> -->
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">Holograph X<sup>™</sup></h5>
                <p class="card-text text-start">The Holograph X™, building on multiphoton stereolithography (SLA), uses a spatial light modulator (SLM) and beam expander to simultaneously cure millions of points of light in a bioink, achieving bioprinting speeds of up to 250,000 voxels per second.</p>
                <!-- <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Expand your GSIS assay window and improve sensitivity to insulin secretagogues with a long-lived, robust model persisting more than 28 days in culture.</li>
                    <li class="text-start">Eliminate islet pooling and data normalization with islet microtissues homogeneous in size and cellular composition.</li>
                    <li class="text-start">Increase efficiency 6-fold by eliminating the need to handpick islets with microtissues available on demand.</li>
                  </ul>  
                </p> -->
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">3D printer</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Holograph X</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('cellink_holographx')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div> 
    </div> 

  </div>
</template>

<script>
export default {
  data() {
    return {
      search_query: null,
    };
  },
  methods: {
    redirect_to_exp_builder: function (expid) {
      this.$router.push(this.$route.path + '/design/' + expid);
      // window.location = "/" + expid;
      // alert("Not connected yet. You will be redirected to the experiment builder.");
    },
    search: function () {
      alert('Not connected yet. You will search for [' + this.search_query + ']');
    },
  },
};
</script>

<style scoped>
.bg-custom {
  background-color: rgba(21, 27, 150, 1);
}
</style>
