<template>
        <div class="card m-2 h-100">
            <!-- <img src="http://beta.benchomatic.com/cellink/img/cellink_logo_6.png" alt="asdfsdf"> -->
            <!-- <div class="card-header">
                <small class="text-muted">Last updated 3 mins ago</small>
            </div> -->
            <div class="card-body">
                <h5 class="card-title">{{data.title}} <font-awesome-icon :icon="data.icon" /></h5>
                <p class="card-text">
                <!-- <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select> -->

                <!-- <select class="form-select form-select-sm" aria-label=".form-select-sm example">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select> -->


                <select class="form-select form-select-sm"
                    @change="onChange($event)"
                    v-model="selected">
                    <option v-for="option in options" v-bind:key="option.value" v-bind:value="option.value" :disabled="option.is_disabled">
                        {{ option.label }}
                    </option>
                </select>

                </p>
                <!-- <p class="card-text">Possibly some text for explanation of what this node it's all about?!</p> -->
                <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
            </div>
            <div class="card-footer"  :class="is_completed">
                <small class="text-right"><span v-html="node_status"></span></small>
            </div>

        </div>
</template>

<script>
export default {
    props: ['data', 'index'],
    data() {
        return {
            index: -1,
            data: null,
            
            options: null,
            selected: "",

            is_completed: "",
            node_status: '&nbsp;',
        }   
    },
    methods: {
        onChange: function(event){
            var self = this;

            // console.log(event);
            self.is_completed = "bg-completed";

            let selected_option =self.options.find(option => option.value == self.selected);
            // console.log("selected_option=", selected_option);
            self.$emit("optionChanged", selected_option);
            
            if((selected_option['cost'] > 0) && (selected_option['duration'] > 0)){
                self.node_status = "Cost: $" + selected_option['cost'] + ", Time: " + selected_option['duration'] + ' days';
            }else if(selected_option['cost'] > 0){
                self.node_status = "Cost: $" + selected_option['cost'];
            }else if(selected_option['duration'] > 0){
                self.node_status = "Time: " + selected_option['duration'] + ' days';
            }
        }
    },
    created() {
        var self = this;
        // console.log(self.$props);
        self.data = self.$props.data;
        self.index = self.$props.index;

        self.options = self.data.data[0].options;
        self.options.unshift({"value": "", "label": "Select", "is_disabled": true});
    }
}
</script>

<style scoped>
.bg-completed{
  background-color: rgb(96, 187, 69, 1);
}
</style>