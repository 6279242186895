<template>
  <div class="container">
    <Navbar />

    <div class="row">
      <div class="col-md">
        <!-- <img src="/img/marketplace_logo.png" width="300" class="mt-4 mb-5 pb-2" /> -->
        <div class="text-center">
          <!-- <img src="/img/logo2.png" width="900" class="mt-4 mb-5" /> -->
          <img src="/img/marketplace/logo.png" width="900" class="mt-4 mb-5" />
        </div>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 my-3">
      <div class="col mb-3" v-for="(category, index) in categories" :key="index">
        <div class="card h-100">
          <h5 class="card-header">
            <strong>{{ category.name }}</strong>
          </h5>
          <!-- avcd -->
          <div class="card-body">
            <div class="row g-0">
              <div class="col-md-2 d-flex align-items-center">
                <figure class="figure">
                  <img :src="category.img" :width="category.img_width" class="figure-img img-fluid rounded" />
                  <!-- style="padding: 10px" /> -->
                  <!-- <figcaption class="figure-caption text-center">Liver Model</figcaption> -->
                </figure>
              </div>

              <div class="col-md-10 float-left">
                <!-- <h5 class="card-title">{{ category.description }}</h5> -->
                <!-- <h5 class="card-text">{{ category.description }}</h5> -->
                <h5 class="card-text">{{ category.description }}</h5>
                <!-- <p class="card-text"> -->
                <!-- <img :src="category.img" :width="category.img_width" class="mt-4 mb-5 pb-2" />
                <br /> -->
                <!-- {{ category.description }} -->
                <!-- </p> -->
                <p class="card-text text-start">
                  <span v-for="(tag, index2) in category.tags" :key="index2" class="me-1">
                    <span class="badge rounded-pill bg-custom">{{ tag }}</span>
                  </span>
                </p>

                <!-- <a :href="category.link" class="btn btn-primary stretched-link">Visit Portal</a> -->
              </div>
            </div>
          </div>

          <div class="card-footer">
            <a :href="category.link" class="btn btn-primary stretched-link">Explore</a>
            <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5 mb-5">
      <div class="col">
        <!-- <div class="card text-white bg-secondary h-100">
          <h5 class="card-header">
            <strong>Recently added services</strong>
          </h5>
          <div class="card-body">
            <div class="row g-0">
              <div class="col-md-2 d-flex align-items-center">
                <figure class="figure">
                </figure>
              </div>

              <div class="col-md-10 float-left">
                <h5 class="card-text">Drug-Induced Liver Injury (DILI) Model from InSphero</h5>
              </div>
            </div>
          </div> -->

        <!-- <div class="card-footer"> -->
        <!-- <a :href="category.link" class="btn btn-primary stretched-link">Explore</a> -->
        <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
        <!-- </div> -->
        <!-- </div> -->

        <div class="alert alert-primary" role="alert">
          <strong>Recently added services:</strong>
          <ul>
            <li>
              <a href="https://insphero.benchomatic.com">Drug-Induced Liver Injury (DILI) model</a>
              offered by InSphero
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
export default {
  name: 'Home',
  components: {
    Navbar,
  },
  data() {
    return {
      categories: [
        {
          name: 'Chemistry & fabrication',
          img: 'img/marketplace/chemistry.svg',
          img_width: 75,
          link: '/marketplace/chemistry',
          description: 'Create and test compounds and reagents, and process samples.',
          tags: [],
        },
        {
          name: 'In vitro biology',
          img: 'img/marketplace/biology-in-vitro.svg',
          img_width: 75,
          link: '/marketplace/biology-in-vitro',
          description: 'Create and test in vitro models, and process samples.',
          tags: [],
        },
        {
          name: 'In vivo biology',
          img: 'img/marketplace/biology-in-vivo.svg',
          img_width: 75,
          link: '/marketplace/biology-in-vivo',
          description: 'Create and test in vivo models, and process samples.',
          tags: [],
        },
        {
          name: 'Computation & software',
          img: 'img/marketplace/computation.svg',
          img_width: 75,
          link: '/marketplace/computation',
          description: 'Model, analyze, and visualize data.',
          tags: [],
        },
      ],
    };
  },
};
</script>

<style scoped>
.bg-custom {
  /* background-color: rgba(21, 27, 150, 1); */
  background-color: rgba(238, 131, 0, 1);
}
</style>
