<template>
  <div>
    <div class="row m-5" v-if="!is_authenticated">
      <span v-html="authentication_status"></span>
    </div>
    <div v-if="is_authenticated">
      <nav class="navbar fixed-top navbar-light" :class="navbar_class">
        <div class="container-fluid">
          <a class="navbar-brand" href="#" @click.prevent="redirect_to_cro_home">
            <img :src="navbar_logo" width="200" alt class="d-inline-block align-top" />
          </a>
          <button class="btn btn-danger" type="submit" @click.prevent="signUserOut">SignOut</button>
        </div>
      </nav>

      <div class="container m-5">
        <h3>{{experiment_header}}</h3>
        <h3>
          <span v-html="total_text"></span>
          <button type="button" class="btn btn-warning m-2" :disabled="bop_in_progress" v-if="total_text" @click.prevent="create_bop">
            <span v-if="bop_in_progress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
            <strong>Submit Workflow</strong>
          </button>
          <span v-html="bop_status"></span>
        </h3>

        <!-- https://getbootstrap.com/docs/5.0/components/card/#grid-cards -->
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 g-4">
          <div class="col" v-for="(node, index) in nodes" v-bind:key="node.id">
            <ExperimentNode :data="node" :index="index+1" @optionChanged="check_completeness" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { header_cellink_1, nodes_cellink_1 } from '@/data/cellink_drug_screening_3d_tumor_microtissues.js';
import { header_insphero_1, nodes_insphero_1 } from '@/data/insphero_drug_induced_liver_injury_y74nd7.js';
import {
  header_stemcell_1,
  nodes_stemcell_1,
} from '@/data/stemcell_liquid_culture_based_hematotoxicity_assay_47cny58.js';
import { header_cnbio_1, nodes_cnbio_1 } from '@/data/cnbio_physio_mimix_model_liver_w5ak74.js';
import { header_cytiva_1, nodes_cytiva_1 } from '@/data/cytiva_3d_tumor_microtissue_37hc734.js';
import { header_generic_1, nodes_generic_1 } from '@/data/3d-tumor-generic-j4sys4.js';
import { header_chimebiologics_1, nodes_chimebiologics_1 } from '@/data/3d-tumor-chimebiologics-j4sys4.js';
import { header_fujifilm_1, nodes_fujifilm_1 } from '@/data/fujifilm-3d-tumor-generic-cb8475g.js';
import { header_milaboratory_1, nodes_milaboratory_1 } from '@/data/3d-tumor-milaboratory-j4sys4.js';

import ExperimentNode from '@/components/ExperimentNode.vue';
import { auth } from '@/services/firebase';
import { appAlertBuilder } from '@/helpers/app.js';

var main_endpoint = process.env.VUE_APP_MAIN_ENDPOINT;
var debug_mode = process.env.VUE_APP_DEBUG_MODE == 'true';

var default_status = '<div class="spinner-border" role="status"></div>';

export default {
  components: {
    ExperimentNode,
  },
  data() {
    return {
      is_authenticated: false,
      signout_redirect: null,
      authentication_status: default_status,
      authed_user: null,

      cro_id: null,
      experiment_id: null,
      experiment_header: null,
      nodes: [],
      total_tasks: null,
      navbar_class: '',
      navbar_logo: '',

      total_text: null,

      bop_status: null,
      bop_in_progress: false,
    };
  },
  methods: {
    create_fake_final_selection: function () {
      var self = this;
      console.log('final_selection_obj=', self.get_final_selection());
      for (let index = 0; index < self.nodes.length; index++) {
        let cur_node = self.nodes[index].data[0].options[1];
        // console.log("node index=", cur_node );
        self.add_to_final_selection(cur_node.task, cur_node);
      }
      console.log('final_selection_obj=', self.get_final_selection());

      document.querySelectorAll('.form-select').forEach((s) => (s.selectedIndex = 1));
      let total_cost = 1000;
      let total_duration = 15;
      self.total_text =
        '<span class="badge bg-primary">Total Cost: $' +
        total_cost.toLocaleString() +
        ' | Total Completion Time: ' +
        total_duration +
        ' days</span>';
    },
    create_bop: function () {
      var self = this;
      self.bop_in_progress = true;
      self.bop_status = null;

      fetch(main_endpoint + '/workflows', {
        method: 'POST',
        body: JSON.stringify({
          user_id: self.authed_user.uid,
          user_data: {
            email: self.authed_user.email,
            uid: self.authed_user.uid,
            displayName: self.authed_user.displayName,
          },
          cro_id: self.cro_id,
          data: self.get_final_selection(),
        }),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (response) {
          console.log(response);
          let success = response.response.success;
          let message = response.response.message;
          let data = response.response.data;

          if (!success) {
            throw new Error(message);
          } else {
            self.bop_in_progress = false;
            self.bop_status = 'Request Submitted! View it <a href="/' + self.cro_id + '/account/1001/step1">here</a>.';
            // self.bop_status = 'Request Submitted!';
            // self.bop_status = 'View it <a href="/' + self.cro_id + '/account">here</a>';
          }
        })
        .catch(function (error) {
          console.log(error);
          self.bop_in_progress = false;
          self.bop_status = appAlertBuilder(error, 'danger');
        });
    },
    redirect_to_cro_home: function () {
      this.$router.push('/' + this.cro_id);
    },
    check_completeness: function (updated_option) {
      var self = this;

      // console.log("updated_option=", updated_option);
      self.add_to_final_selection(updated_option.task, updated_option);

      if (self.cro_id == 'cellink' && self.experiment_id == 'drug-screening-3d-tumor-microtissues-6f3xt64') {
        self.cellnk_estimate_total_1();
      } else if (self.cro_id == 'insphero' && self.experiment_id == 'drug-induced-liver-injury-y74nd7') {
        self.insphero_estimate_total_1();
      } else if (
        self.cro_id == 'stemcell' &&
        self.experiment_id == 'liquid-culture-based-hematotoxicity-assay-47cny58'
      ) {
        self.stemcell_estimate_total_1();
      } else if (self.cro_id == 'cnbio' && self.experiment_id == 'physio-mimix-model-liver-w5ak74') {
        self.cnbio_estimate_total_1();
      } else if (self.cro_id == 'cytiva' && self.experiment_id == '3d-tumor-microtissue-37hc734') {
        self.cytiva_estimate_total_1();
      } else if (self.cro_id == 'exampleco' && self.experiment_id == '3d-tumor-generic-j4sys4') {
        self.generic_estimate_total_1();
      } else if (self.cro_id == 'chimebiologics' && self.experiment_id == '3d-tumor-chimebiologics-j4sys4') {
        self.chimebiologics_estimate_total_1();
      } else if (self.cro_id == 'milaboratory' && self.experiment_id == '3d-tumor-milaboratory-j4sys4') {
        self.milaboratory_estimate_total_1();
      } else if (self.cro_id == 'fujifilm' && self.experiment_id == 'fujifilm-3d-tumor-generic-cb8475g') {
        self.fujifilm_estimate_total_1();
      } else {
        console.log('Unable to estimate total time/cost for this experiment ', self.cro_id, self.experiment_id);
      }
    },
    cellnk_estimate_total_1: function () {
      // used for cellink demo
      // Total Cost = B1 + B3 +(B4 x B5 x B6 x B7) + B8 + B9 + B10
      // Total Time = B3

      var self = this;
      let tmp = JSON.parse(localStorage.getItem('final_selections')) || {};

      if (Object.keys(tmp).length === self.total_tasks) {
        let total_cost = 0;
        total_cost +=
          tmp['1']['cost'] +
          tmp['3']['cost'] +
          tmp['4']['cost'] * tmp['5']['value'] * tmp['6']['value'] * tmp['7']['cost'] +
          tmp['8']['cost'] +
          tmp['9']['cost'] +
          tmp['10']['cost'];

        let total_duration = 0;
        total_duration += tmp['3']['duration'];

        self.total_text =
          '<span class="badge bg-primary">Total Cost: $' +
          total_cost.toLocaleString() +
          ' | Total Completion Time: ' +
          total_duration +
          ' days</span>';
      } else {
        console.log('Not enough tasks completed..', Object.keys(tmp).length, self.total_tasks);
      }
    },
    insphero_estimate_total_1: function () {
      // used for inshpero demo
      // Total Cost = B1 + B3 +(B4 x B5 x B6 x B7) + B8
      // Total Time = B3

      var self = this;
      let tmp = JSON.parse(localStorage.getItem('final_selections')) || {};

      if (Object.keys(tmp).length === self.total_tasks) {
        let total_cost = 0;
        total_cost +=
          tmp['1']['cost'] +
          tmp['3']['cost'] +
          tmp['4']['cost'] * tmp['5']['value'] * tmp['6']['value'] * tmp['7']['cost'] +
          tmp['8']['cost'];

        let total_duration = 0;
        total_duration += tmp['3']['duration'];

        self.total_text =
          '<span class="badge bg-primary">Total Cost: $' +
          total_cost.toLocaleString() +
          ' | Total Completion Time: ' +
          total_duration +
          ' days</span>';
      } else {
        console.log('Not enough tasks completed..', Object.keys(tmp).length, self.total_tasks);
      }
    },
    stemcell_estimate_total_1: function () {
      // used for inshpero demo
      // Total Cost = B1 + B3 +(B4 x B5 x B6 x B7)
      // Total Time = B3

      var self = this;
      let tmp = JSON.parse(localStorage.getItem('final_selections')) || {};

      if (Object.keys(tmp).length === self.total_tasks) {
        let total_cost = 0;
        total_cost +=
          tmp['1']['cost'] +
          tmp['3']['cost'] +
          tmp['4']['cost'] * tmp['5']['value'] * tmp['6']['value'] * tmp['7']['cost'];

        let total_duration = 0;
        total_duration += tmp['3']['duration'];

        self.total_text =
          '<span class="badge bg-primary">Total Cost: $' +
          total_cost.toLocaleString() +
          ' | Total Completion Time: ' +
          total_duration +
          ' days</span>';
      } else {
        console.log('Not enough tasks completed..', Object.keys(tmp).length, self.total_tasks);
      }
    },
    cnbio_estimate_total_1: function () {
      // used for cnbio demo
      // Total Cost = B1 + B3 +(B4 x B5 x B6 x B7) + B8
      // Total Time = B3

      var self = this;
      let tmp = JSON.parse(localStorage.getItem('final_selections')) || {};

      if (Object.keys(tmp).length === self.total_tasks) {
        let total_cost = 0;
        total_cost +=
          tmp['1']['cost'] +
          tmp['3']['cost'] +
          tmp['4']['cost'] * tmp['5']['value'] * tmp['6']['value'] * tmp['7']['cost'] +
          tmp['8']['cost'];

        let total_duration = 0;
        total_duration += tmp['3']['duration'];

        self.total_text =
          '<span class="badge bg-primary">Total Cost: $' +
          total_cost.toLocaleString() +
          ' | Total Completion Time: ' +
          total_duration +
          ' days</span>';
      } else {
        console.log('Not enough tasks completed..', Object.keys(tmp).length, self.total_tasks);
      }
    },
    chimebiologics_estimate_total_1: function () {
      // used for cnbio demo
      // Total Cost = B1 + B3 +(B4 x B5 x B6 x B7) + B8
      // Total Time = B3

      var self = this;
      let tmp = JSON.parse(localStorage.getItem('final_selections')) || {};

      if (Object.keys(tmp).length === self.total_tasks) {
        let total_cost = 0;
        total_cost +=
          tmp['1']['cost'] +
          tmp['3']['cost'] +
          tmp['4']['cost'] * tmp['5']['value'] * tmp['6']['value'] * tmp['7']['cost'] +
          tmp['8']['cost'];

        let total_duration = 0;
        total_duration += tmp['3']['duration'];

        self.total_text =
          '<span class="badge bg-primary">Total Cost: $' +
          total_cost.toLocaleString() +
          ' | Total Completion Time: ' +
          total_duration +
          ' days</span>';
      } else {
        console.log('Not enough tasks completed..', Object.keys(tmp).length, self.total_tasks);
      }
    },
    milaboratory_estimate_total_1: function () {
      // used for cnbio demo
      // Total Cost = B1 + B3 +(B4 x B5 x B6 x B7) + B8
      // Total Time = B3

      var self = this;
      let tmp = JSON.parse(localStorage.getItem('final_selections')) || {};

      if (Object.keys(tmp).length === self.total_tasks) {
        let total_cost = 0;
        total_cost +=
          tmp['1']['cost'] +
          tmp['3']['cost'] +
          tmp['4']['cost'] * tmp['5']['value'] * tmp['6']['value'] * tmp['7']['cost'] +
          tmp['8']['cost'];

        let total_duration = 0;
        total_duration += tmp['3']['duration'];

        self.total_text =
          '<span class="badge bg-primary">Total Cost: $' +
          total_cost.toLocaleString() +
          ' | Total Completion Time: ' +
          total_duration +
          ' days</span>';
      } else {
        console.log('Not enough tasks completed..', Object.keys(tmp).length, self.total_tasks);
      }
    },
    cytiva_estimate_total_1: function () {
      // used for cellink demo
      // Total Cost = B1 + B3 +(B4 x B5 x B6 x B7) + B8 + B9 + B10
      // Total Time = B3

      var self = this;
      let tmp = JSON.parse(localStorage.getItem('final_selections')) || {};

      if (Object.keys(tmp).length === self.total_tasks) {
        let total_cost = 0;
        total_cost +=
          tmp['1']['cost'] +
          tmp['3']['cost'] +
          tmp['4']['cost'] * tmp['5']['value'] * tmp['6']['value'] * tmp['7']['cost'] +
          tmp['8']['cost'] +
          tmp['9']['cost'] +
          tmp['10']['cost'];

        let total_duration = 0;
        total_duration += tmp['3']['duration'];

        self.total_text =
          '<span class="badge bg-primary">Total Cost: $' +
          total_cost.toLocaleString() +
          ' | Total Completion Time: ' +
          total_duration +
          ' days</span>';
      } else {
        console.log('Not enough tasks completed..', Object.keys(tmp).length, self.total_tasks);
      }
    },

    generic_estimate_total_1: function () {
      // used for cellink demo
      // Total Cost = B1 + B3 +(B4 x B5 x B6 x B7) + B8 + B9 + B10
      // Total Time = B3

      var self = this;
      let tmp = JSON.parse(localStorage.getItem('final_selections')) || {};

      if (Object.keys(tmp).length === self.total_tasks) {
        let total_cost = 0;
        total_cost +=
          tmp['1']['cost'] +
          tmp['3']['cost'] +
          tmp['4']['cost'] * tmp['5']['value'] * tmp['6']['value'] * tmp['7']['cost'] +
          tmp['8']['cost'] +
          tmp['9']['cost'] +
          tmp['10']['cost'];

        let total_duration = 0;
        total_duration += tmp['3']['duration'];

        self.total_text =
          '<span class="badge bg-primary">Total Cost: $' +
          total_cost.toLocaleString() +
          ' | Total Completion Time: ' +
          total_duration +
          ' days</span>';
      } else {
        console.log('Not enough tasks completed..', Object.keys(tmp).length, self.total_tasks);
      }
    },

    fujifilm_estimate_total_1: function () {
      // used for cellink demo
      // Total Cost = B1 + B3 +(B4 x B5 x B6 x B7) + B8 + B9 + B10
      // Total Time = B3

      var self = this;
      let tmp = JSON.parse(localStorage.getItem('final_selections')) || {};

      if (Object.keys(tmp).length === self.total_tasks) {
        let total_cost = 0;
        total_cost +=
          tmp['1']['cost'] +
          tmp['3']['cost'] +
          tmp['4']['cost'] * tmp['5']['value'] * tmp['6']['value'] * tmp['7']['cost'] +
          tmp['8']['cost'] +
          tmp['9']['cost'] +
          tmp['10']['cost'];

        let total_duration = 0;
        total_duration += tmp['3']['duration'];

        self.total_text =
          '<span class="badge bg-primary">Total Cost: $' +
          total_cost.toLocaleString() +
          ' | Total Completion Time: ' +
          total_duration +
          ' days</span>';
      } else {
        console.log('Not enough tasks completed..', Object.keys(tmp).length, self.total_tasks);
      }
    },

    load_experiment_data: function () {
      var self = this;

      if (self.cro_id == 'cellink' && self.experiment_id == 'drug-screening-3d-tumor-microtissues-6f3xt64') {
        self.experiment_header = header_cellink_1;
        self.nodes = nodes_cellink_1;
        self.navbar_class = 'bg-cellink';
        self.navbar_logo = '/img/cellink/logo.png';
      } else if (self.cro_id == 'insphero' && self.experiment_id == 'drug-induced-liver-injury-y74nd7') {
        self.experiment_header = header_insphero_1;
        self.nodes = nodes_insphero_1;
        self.navbar_class = 'bg-insphero';
        self.navbar_logo = '/img/insphero/logo.png';
      } else if (
        self.cro_id == 'stemcell' &&
        self.experiment_id == 'liquid-culture-based-hematotoxicity-assay-47cny58'
      ) {
        self.experiment_header = header_stemcell_1;
        self.nodes = nodes_stemcell_1;
        self.navbar_class = 'bg-stemcell';
        self.navbar_logo = '/img/stemcell/logo.png';
      } else if (self.cro_id == 'cnbio' && self.experiment_id == 'physio-mimix-model-liver-w5ak74') {
        self.experiment_header = header_cnbio_1;
        self.nodes = nodes_cnbio_1;
        self.navbar_class = 'bg-cnbio';
        self.navbar_logo = '/img/cnbio/logo.png';
      } else if (self.cro_id == 'cytiva' && self.experiment_id == '3d-tumor-microtissue-37hc734') {
        self.experiment_header = header_cytiva_1;
        self.nodes = nodes_cytiva_1;
        self.navbar_class = 'bg-cytiva';
        self.navbar_logo = '/img/cytiva/logo.png';
      } else if (self.cro_id == 'exampleco' && self.experiment_id == '3d-tumor-generic-j4sys4') {
        self.experiment_header = header_generic_1;
        self.nodes = nodes_generic_1;
        self.navbar_class = 'bg-generic';
        self.navbar_logo = '/img/generic/logo.png';
      } else if (self.cro_id == 'chimebiologics' && self.experiment_id == '3d-tumor-chimebiologics-j4sys4') {
        self.experiment_header = header_chimebiologics_1;
        self.nodes = nodes_chimebiologics_1;
        self.navbar_class = 'bg-chimebiologics';
        self.navbar_logo = '/img/chimebiologics/logo.png';
      } else if (self.cro_id == 'milaboratory' && self.experiment_id == '3d-tumor-milaboratory-j4sys4') {
        self.experiment_header = header_milaboratory_1;
        self.nodes = nodes_milaboratory_1;
        self.navbar_class = 'bg-milaboratory';
        self.navbar_logo = '/img/milaboratory/logo.png';
      } else if (self.cro_id == 'fujifilm' && self.experiment_id == 'fujifilm-3d-tumor-generic-cb8475g') {
        self.experiment_header = header_fujifilm_1;
        self.nodes = nodes_fujifilm_1;
        self.navbar_class = 'bg-fujifilm';
        self.navbar_logo = '/img/fujifilm/logo.png';
      } else {
        self.experiment_header = 'Unknown Experiment ID';
        self.nodes = [];
      }
    },
    get_final_selection: function () {
      let tmp = JSON.parse(localStorage.getItem('final_selections')) || {};
      // console.log("getting final selection=", tmp);
      return tmp;
    },
    add_to_final_selection: function (k, v) {
      // console.log("key=",k, "value=", v);
      let tmp = this.get_final_selection();
      tmp[k] = v;
      localStorage.setItem('final_selections', JSON.stringify(tmp));
      // console.log("after adding to final selection:", tmp);
    },
    cleanup_final_selection: function () {
      localStorage.setItem('final_selections', JSON.stringify({}));
      console.log('cleaned up!');
    },
    signUserOut: function () {
      var self = this;
      self.signout_redirect = '/' + self.cro_id;
      auth.signOut();
    },
  },
  created() {
    var self = this;
    // console.log(self.$route.params, self.$route.params.experiment_id);
    self.experiment_id = self.$route.params.experiment_id;
    self.cro_id = self.$route.params.cro_id;

    self.is_authenticated = false;
    auth.onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        console.log('is signed', user.email, user.displayName);
        self.is_authenticated = true;
        self.authentication_status = 'Authenticated!';
        self.authed_user = user;
      } else {
        // User is NOT signed in.
        console.log('NOT signed', user, self.signout_redirect);
        self.is_authenticated = false;
        if (self.signout_redirect == null) {
          self.$router.push('/auth?redirectTo=/' + self.cro_id + '/design/' + self.experiment_id);
        } else {
          self.$router.push('/' + self.cro_id);
        }
      }
    });

    self.cleanup_final_selection();
    self.load_experiment_data();

    for (let index = 0; index < self.nodes.length; index++) {
      const tasks_in_node = self.nodes[index].data.length;
      self.total_tasks += tasks_in_node;
    }
    console.log('Found ' + self.total_tasks + ' tasks in total.');

    // for quick testing
    // self.create_fake_final_selection();
  },
};
</script>

<style scoped>
.bg-cellink {
  background-color: rgba(21, 27, 150, 1);
}
.bg-insphero {
  background-color: rgba(115, 142, 32, 1);
}
.bg-stemcell {
  background-color: rgba(247, 247, 245, 1);
}
.bg-cnbio {
  background-color: rgba(247, 247, 245, 1);
}

.bg-cytiva {
  background-color: rgba(0, 136, 112, 1);
}

.bg-generic {
  background-color: rgba(21, 27, 150, 1);
}

.bg-chimebiologics {
  background-color: rgba(247, 247, 245, 1);
}

.bg-milaboratory {
  background-color: rgba(247, 247, 245, 1);
}

.bg-fujifilm {
  background-color: rgba(247, 247, 245, 1);
}
</style>

