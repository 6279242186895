<template>
  <div class="container">
    <Navbar />

    <div class="row m-2">
      <div class="col">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <router-link class="breadcrumb-item" to="/">Home</router-link>
            <li class="breadcrumb-item active" aria-current="page">In vivo biology</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 my-3">
      <div class="col mb-3" v-for="(subcategory, index) in subcategories" :key="index">
        <div class="card h-100">
          <h5 class="card-header">{{ subcategory.name }}</h5>
          <div class="card-body">
            <h5 class="card-title">{{ subcategory.description }}</h5>
            <!-- <p class="card-text"> -->
            <!-- <img :src="subcategory.img" :width="subcategory.img_width" class="mt-4 mb-5 pb-2" />
              <br /> -->
            <!-- {{ subcategory.description }} -->
            <!-- </p> -->
            <p class="card-text text-start">
              <!-- <span v-for="(tag, index2) in subcategory.tags" :key="index2" class="me-1">
                <span class="badge rounded-pill bg-custom">{{ tag }}</span>
              </span> -->
            </p>

            <!-- <a :href="subcategory.link" class="btn btn-primary stretched-link">Visit Portal</a> -->
          </div>
          <div class="card-footer">
            <button href="#" class="btn btn-dark btn-sm" type="button" disabled>Coming Soon</button>
            <!-- <a :href="subcategory.link" class="btn btn-primary stretched-link">Explore</a> -->
            <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
export default {
  name: 'Home',
  components: {
    Navbar,
  },
  data() {
    return {
      subcategories: [
        {
          name: 'Model generation services',
          // img: 'img/insphero/marketplace_logo.png',
          // img_width: 200,
          // link: '/marketplace/chemistry',
          description: '',
          tags: [],
        },
        {
          name: 'Drug metabolism and pharmacokinetics',
          // img: 'img/insphero/marketplace_logo.png',
          // img_width: 200,
          // link: '/marketplace/chemistry',
          description: '',
          tags: ['Creative Bioarray', 'Charles River'],
        },
        {
          name: 'Tumorigenicity assay',
          // img: 'img/insphero/marketplace_logo.png',
          // img_width: 200,
          // link: '/marketplace/chemistry',
          description: '',
          tags: [],
        },
      ],
    };
  },
};
</script>

<style scoped>
.bg-custom {
  /* background-color: rgba(21, 27, 150, 1); */
  background-color: rgba(238, 131, 0, 1);
}
</style>
