<template>
  <div class="container">
    <Navbar />

    <div class="row">
      <div class="col-md">
        <!-- <img src="/img/marketplace_logo.png" width="300" class="mt-4 mb-5 pb-2" /> -->
        <div class="text-center">
          <img src="/img/logo2.png" width="900" class="mt-4 mb-5" />
        </div>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 my-3">
      <div class="col mb-3" v-for="(company, index) in companies" :key="index">
        <div class="card h-100">
          <h5 class="card-header">{{ company.name }}</h5>
          <div class="card-body">
            <!-- <h5 class="card-title">InSphero</h5> -->
            <p class="card-text">
              <img :src="company.img" :width="company.img_width" class="mt-4 mb-5 pb-2" />
              <br />
              {{ company.description }}
            </p>
            <p class="card-text text-start">
              <span v-for="(tag, index2) in company.tags" :key="index2" class="me-1">
                <span class="badge rounded-pill bg-custom">{{ tag }}</span>
              </span>
            </p>

            <!-- <a :href="company.link" class="btn btn-primary stretched-link">Visit Portal</a> -->
          </div>
          <div class="card-footer">
            <a :href="company.link" class="btn btn-primary stretched-link">Visit Portal</a>
            <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
export default {
  name: 'Home',
  components: {
    Navbar,
  },
  data() {
    return {
      companies: [
        {
          name: 'InSphero',
          img: 'img/insphero/marketplace_logo.png',
          img_width: 200,
          link: '/insphero',
          description:
            'InSphero enables large-scale, reproducible production of scaffold-free 3D microtissues driven solely by cellular self-assembly. The company specializes in delivering assay-ready and custom 3D models derived from liver, pancreatic islet, and tumor tissues, to provide unrivaled biological insight into liver toxicology, metabolic diseases, and oncology.',
          tags: [
            'Engineered tissue',
            'Spheroids',
            'Liver',
            'Oncology',
            'NASH',
            'Disease model',
            'Toxicology model',
            'Drug screening',
            'Tumor model',
            'PDX',
            'Flow model',
            'Diabetes model',
            'Endocrine model',
            'Islet model',
            'Β-cell',
            'α-cell',
          ],
        },
        {
          name: 'Cellink',
          img: 'img/cellink/marketplace_logo.png',
          img_width: 266,
          link: '/cellink',
          description:
            'Cellink’s best-in-class bioinks, bioprinters, software and services are being used by the foremost academics and R&D labs and have enabled critical breakthroughs in a wide range of applications from 3D cell culturing to tissue engineering to drug development.',
          tags: [
            'Tumor',
            'Open plate',
            'Bioprinting',
            'Drug screening',
            'Spheroids',
            'BIO X6',
            'I-DOT',
            'CELLCCYTE X',
            '3D printer',
            'LUMEN X',
            'Holograph X',
            'INKREDIBLE+',
          ],
        },
        {
          name: 'STEMCELL Technologies',
          img: 'img/stemcell/marketplace_logo.png',
          img_width: 266,
          link: '/stemcell',
          description:
            'STEMCELL Technologies provides high-quality cell culture media, cell separation technologies, instruments, accessory products, and educational resources to scientists around the world working on stem cell, immunology, cancer, regenerative medicine, and cellular therapy research.',
          tags: [
            'Engineered tissue',
            'Spheroids',
            'Hematopoietic Stem Cells',
            'Erythroid',
            'Myeloid',
            'Megakaryocyte',
            'Blood',
            'Disease Model',
            'Toxicology',
            'Drug Screening',
            'Adipose tissue',
            'Tumor model',
            'Islet model',
            'Diabetes model',
          ],
        },
        {
          name: 'CN Bio',
          img: 'img/cnbio/marketplace_logo.png',
          img_width: 266,
          link: '/cnbio',
          description:
            'CN Bio’s microphysiological systems (also known as Organ-on-Chip) bring precision and human-relevant data to drug development and other commercial or research scenarios. They enable researchers to work on novel and innovative human modalities including gene editing, therapeutic antibodies, proteins and cellular therapies.',
          tags: [
            'Engineered tissue',
            'Liver',
            'NAFLD',
            'NASH',
            'Toxicology',
            'Drug Screening',
            'Disease Model',
            'Oncology',
            'Diabetes model',
            'Endocrine model',
          ],
        },
        {
          name: 'Cytiva',
          img: 'img/cytiva/marketplace_logo.png',
          img_width: 266,
          link: '/cytiva',
          description:
            'Cytiva’s services range from biological research to developing innovative vaccines, biologic drugs, and novel cell and gene therapies. Cytiva brings speed, efficiency and capacity to research and manufacturing workflows, enabling the development, manufacture and delivery of transformative medicines to patients.',
          tags: [
            'Therapeutics',
            'Vaccines',
            'Biologics',
            'Drug development',
            'Cell therapy',
            'Gene therapy',
            'Manufacturing',
            'Bioreactors',
            'Cell culture',
            'Cell lines',
          ],
        },
        {
          name: 'Fujifilm Cellular Dynamics',
          img: 'img/fujifilm/marketplace_logo.png',
          img_width: 266,
          link: '/fujifilm',
          description:
            'FUJIFILM Cellular Dynamics (FCDI)  is a leading developer and manufacturer of human cells used in drug discovery, toxicity testing, stem cell banking, and cell therapy development. FCDI’s technology offers the potential to create induced pluripotent stem cells (iPSCs) from anyone, starting with a standard blood draw, and followed by the powerful capability to develop into virtually any cell type in the human body.',
          tags: [
            'Hepatocytes',
            'Cardiomycoytes',
            'Neurons',
            'Induced pluripotent stem cells',
            'Cell culture media',
            'Engineered tissue',
            'Tumor cells',
            'Disease model',
            'Toxicology model',
            'Drug screening',
            'PDX',
            'Diabetes model',
            'Endocrine model',
            'Islet model',
            'Β-cell',
            'α-cell',
          ],
        },
        {
          name: 'Chime Biologics',
          img: 'img/chimebiologics/marketplace_logo.png',
          img_width: 266,
          link: '/chimebiologics',
          description:
            'Chime Biologics provides customer-centric and cost-effective services for timely premier-quality biopharmaceutical development and manufacturing. We provide one-stop comprehensive solution supporting our customers from early-stage biopharmaceutical development through late-stage clinical and commercial manufacturing, catering to the evolving needs of the dynamic biopharmaceutical industry.',
          tags: [
            'Therapeutics',
            'Vaccines',
            'Biologics',
            'Drug development',
            'Cell therapy',
            'Gene therapy',
            'Manufacturing',
            'Bioreactors',
            'Cell culture',
            'Cell lines',
          ],
        },
        {
          name: 'MiLaboratory',
          img: 'img/milaboratory/marketplace_logo.png',
          img_width: 266,
          link: '/milaboratory',
          description:
            'Our team has experience in the field of adaptive immunity starting from 2009. Since that time we published dozens of research papers in top journals and developed a software which became the most popular among the academic community and leading biotech and pharma companies. We enjoy working together and delivering reliable and efficient tools to the community of clinicians, investigators and scientists.',
          tags: [
            'Immunology',
            'Immunotherapeutics',
            'Software',
            'Adaptive immunity',
            'RNA-Seq',
            'PCR',
            'NGS',
            'Molecular identifiers',
            'Repertoire extraction',
            'Analysis services',
            'Experiment planning',
          ],
        },
        {
          name: 'Aptagen',
          img: 'img/aptagen/marketplace_logo.png',
          img_width: 150,
          link: '#',
          description:
            'Aptagen is a global leader in developing ‘synthetic antibodies’ called aptamers, with over 25 years of experience.  Aptamers are target-recognition elements with high affinity and specificity against targets such as small molecules, protein biomarkers, cells, and tissues. Aptamers are an alternative platform to that of antibodies for diagnostic, therapeutic, and bio-industrial applications.',
          tags: [
            'Aptamers',
            'Synthetic antibodies',
            'Small molecules',
            'Protein biomarkers',
            'Cells and tissues',
            'Molecular diagnostics',
            'Therapeutics',
            'Drug discovery',
            'Peptimers',
            'Biomarker discovery',
            'Chemical synthesis',
          ],
        },
        {
          name: 'ChemPartner',
          img: 'img/chempartner/marketplace_logo.png',
          img_width: 266,
          link: '#',
          description:
            'ChemPartner offers a broad range of drug discovery, development, and manufacturing services including discovery chemistry and biologics, biology, pharmacology, DMPK, and exploratory toxicology as well as small molecule and biologics CMC development and manufacturing.',
          tags: [
            'Drug discovery',
            'Pharmacology',
            'DMPK',
            'Toxicology',
            'Therapeutics',
            'Biologics',
            'Drug development',
            'Cell therapy',
            'Gene therapy',
            'Manufacturing',
            'Bioreactors',
            'Cell culture',
            'Cell lines',
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.bg-custom {
  /* background-color: rgba(21, 27, 150, 1); */
  background-color: rgba(238, 131, 0, 1);
}
</style>
