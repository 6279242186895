<template>
  <div class="container">
    <Navbar />

    <div class="row m-2">
      <div class="col">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <router-link class="breadcrumb-item" to="/">Home</router-link>
            <li class="breadcrumb-item active" aria-current="page">Contact</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-6 md-offset-3">
        <div class="mb-3">
          <label for="customerName" class="form-label">Name:</label>
          <input
            type="text"
            v-model="customerName"
            class="form-control"
            id="customerName"
            placeholder="Your full name" />
        </div>
        <div class="mb-3">
          <label for="customerEmail" class="form-label">Email address:</label>
          <input
            type="email"
            v-model="customerEmail"
            class="form-control"
            id="customerEmail"
            placeholder="name@example.com" />
        </div>
        <div class="mb-3">
          <label for="customerMessage" class="form-label">Message:</label>
          <textarea class="form-control" v-model="customerMessage" id="customerMessage" rows="3"></textarea>
        </div>

        <button
          v-if="!email_sent"
          class="btn btn-primary btn-block mb-3"
          type="button"
          :disabled="contact_in_progress"
          @click.prevent="contact()">
          <div v-if="contact_in_progress" class="spinner-border spinner-border-sm" role="status"></div>
          &nbsp; Send message
        </button>
        <span v-html="contact_status"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { appAlertBuilder } from '@/helpers/app.js';
import Navbar from '@/components/Navbar.vue';

const custom_prod_endpoint = 'https://9ddyg5692f.execute-api.us-east-1.amazonaws.com/prod';
const debug = process.env.VUE_APP_DEBUG_MODE == 'true';

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      contact_in_progress: null,
      contact_status: null,

      customerEmail: null,
      customerName: null,
      customerMessage: null,

      email_sent: null,
    };
  },
  methods: {
    init: function () {
      var self = this;
      console.log('Initiallized.');
    },

    contact: function () {
      var self = this;
      self.contact_in_progress = true;
      self.contact_status = null;

      // await self.$store.dispatch('getValidToken');
      fetch(`${custom_prod_endpoint}/contact`, {
        method: 'POST',
        headers: {
          'Authorization': self.$store.state.token,
        },
        body: JSON.stringify({
          name: self.customerName,
          email: self.customerEmail,
          message: self.customerMessage,
        }),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (response) {
          console.log(response);
          let success = response.response.success;
          let message = response.response.message;
          let data = response.response.data;

          if (!success) {
            throw new Error(message);
          } else {
            self.contact_in_progress = false;
            self.contact_status = appAlertBuilder(
              'Your message was successfully sent! Someone from Benchomatic will get in touch with you soon.',
              'success'
            );
            self.email_sent = true;
          }
        })
        .catch(function (error) {
          console.log(error);
          self.contact_in_progress = false;
          self.contact_status = appAlertBuilder(error, 'danger');
        });
    },
  },
  created() {
    var self = this;
    self.init();
  },
};
</script>

<style>
</style>