<template>
  <div>
    
    <!-- <nav class="navbar fixed-top navbar-light" style="background-color: #8cb802;"> -->
    <nav class="navbar fixed-top navbar-light bg-custom">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="img/insphero/logo.png" width="200" alt="" class="d-inline-block align-top"></a>
        <form class="d-flex">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"  size="50"
            v-model="search_query">
          <button class="btn btn-success" type="submit"
            @click.prevent="search">Search</button>
        </form>
      </div>
    </nav>


    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            Featured
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/insphero/liver.png" class="figure-img img-fluid rounded" alt="InSphero" style="padding: 30px">
                <figcaption class="figure-caption text-center">Liver model</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D InSight™ Drug-Induced Liver Injury (DILI) Model</h5>
                <p class="card-text text-start">3D InSight™ Hepatotoxicity Testing assesses the effect of compounds on cellular viability in our standardized 3D InSight™ Human Liver Microtissues, developed from primary human hepatocytes in co-culture with Kupffer cells and liver endothelial cells (LECs). Cell viability dose-response curves are determined following a repeat dose exposure to compounds. </p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Predict DILI with twice the sensitivity of standard 2D Primary Human Hepatocytes (PHH) assays without sacrificing specificity.</li>
                    <li class="text-start">Test in our mechanistically relevant, extensively validated 3D liver model, developed using pooled multi-donor PHH in co-culture with Kupffer cells and liver endothelial cells.</li>
                    <li class="text-start">Customize your study with additional functional and mechanistic endpoints as needed.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Liver</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ 96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ 384-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ Flow</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Toxicology Model</span>&nbsp;
                </p>
                <!-- <p class="text-center">
                  <a href="#" class="btn btn-warning" 
                    @click.prevent="redirect_to_exp_builder('drug-induced-liver-injury-y74nd7')"
                    >Order Now</a>
                </p> -->
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('generic_2')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div> 

      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/insphero/liver.png" class="figure-img img-fluid rounded" alt="InSphero" style="padding: 30px">
                <figcaption class="figure-caption text-center">Liver model</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D InSight™ Liver Steatosis Model</h5>
                <p class="card-text text-start">The 3D InSight™ Steatosis Model is an advanced human disease model that enables you to screen compounds and investigate disease pathophysiology using a physiologically relevant human liver microtissue that recapitulates the first stage of  non-alcoholic fatty liver disease:  accumulation of lipids in liver hepatocytes. Available as an assay-ready model delivered to your lab, or for use in 3D InSight™ Discovery Services, this diet-induced steatosis model includes a microtissue produced from a co-culture of healthy primary human hepatocytes, Kupffer cells, and liver endothelial cells, combined with specially formulated media and preparation of free fatty acids bound to bovine serum albumin for lipid-loading.</p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Maintain susceptibility to induction of steatosis for up to 21 days in culture.</li>
                    <li class="text-start">Assess multi-parametric steatotic endpoints, such as intra-cellular lipid content, reactive oxygen species production, mitochondrial impairment, and apoptosis, as well as changes at the mRNA and protein levels.</li>
                    <li class="text-start">Evaluate efficacy and potency of steatosis drugs using InSphero-tested technical protocols with a 14-day assay window.</li>
                    <li class="text-start">Receive assay-ready disease models, ready to induce steatosis upon arrival at your lab after an incubation period of only a few hours.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Liver</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Steatosis</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ 96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ 384-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ Flow</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('insphero_dili')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   
    </div> 

    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/insphero/liver.png" class="figure-img img-fluid rounded" alt="InSphero" style="padding: 30px">
                <figcaption class="figure-caption text-center">Liver model</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D InSight™ Liver NASH Model</h5>
                <p class="card-text text-start">The InSphero 3D InSight™ Human Liver NASH Model consists of an advanced microtissue co-culture of healthy primary human liver cells combined with specially formulated media and cocktails that induce the progression of NAFLD and NASH from steatosis to liver inflammation and fibrosis. Ideal for investigating mechanisms of non-alcoholic steatohepatitis (NASH) induction, in vitro screening of anti-NASH drug efficacy, and safety assessment of drug candidates in a relevant disease background, this powerful model includes all the critical liver cells and inducers needed to recapitulate the progression of human fatty liver disease.</p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Rely on a physiologically relevant model composed of primary human hepatocytes (PHHs), hepatic stellate cells (HSCs), Kupffer cells, and liver endothelial cells (LECs).</li>
                    <li class="text-start">Expand your assay window by leveraging a pre-qualified model that exhibits pro-inflammatory marker induction reflecting the in vivo disease state: MCP-1/CCL2, TNF-α, MIP-1α/CCL3, IL-8, IL-6, and IP-10.</li>
                    <li class="text-start">Perform reproducible, throughput-compatible NASH drug screening using a scalable Akura™ plate technology, amenable to a host of certified application endpoints.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Liver</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">NASH</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ 96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ 384-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ Flow</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('insphero_dili')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   

      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/insphero/tumor.png" class="figure-img img-fluid rounded" alt="InSphero" style="padding: 30px">
                <figcaption class="figure-caption text-center">Tumor Model</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D InSight™ Assay-Ready Tumor Microtissues</h5>
                <p class="card-text text-start">InSphero assay-ready 3D InSight™ Tumor Microtissues are designed to simplify and accelerate oncology drug efficacy testing. Using the 3D Select™ Process, our oncology experts have identified optimal culture conditions for large-scale, reproducible production of 3D spheroid models that better reflect complex tumor biology. 
</p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Leverage physiologically relevant 3D tumor co-cultures composed of tumor and stromal components — with no artificial matrices.</li>
                    <li class="text-start">Assess single agent or combinatorial efficacy of small molecule, biological, ADC, or immunomodulatory drugs using 3D optimized endpoints.</li>
                    <li class="text-start">Quality controlled pre-validated models that optimize your staff resources and ensure robust, reproducible results.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Oncology</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Tumor</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ 96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ 384-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ Flow</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('insphero_dili')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   
    </div> 

    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/insphero/tumor.png" class="figure-img img-fluid rounded" alt="InSphero" style="padding: 30px">
                <figcaption class="figure-caption text-center">Tumor Model</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D InSight™ PDX Microtissues</h5>
                <p class="card-text text-start">3D InSight™ Tumor Microtissues from Patient-Derived Xenografts (PDX) are custom assay-ready 3D in vitro tumor models engineered to reflect complex tumor biology without the use of artificial matrices. Ideal for early-stage cancer drug efficacy testing and screening applications, these advanced cancer models help bridge the gap between preclinical animal studies and translation of outcome into clinical applications and enable oncologists to quickly identify the most promising mouse PDX models for subsequent in vivo testing. </p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Screen and profile drug candidates using cost-effective PDX-derived, 3D in vitro tumor models that recapitulate the heterogeneity of tumor microenvironments.</li>
                    <li class="text-start">Conduct assays for biomarker discovery and disease-oriented drug development using relevant 3D models chosen for genetic alteration or tumor type.</li>
                    <li class="text-start">Preselect agents of interest for further preclinical development with an oncology drug discovery tool designed to reduce research costs and optimize human tumor material.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Oncology</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Tumor</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">PDX</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ 96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ 384-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ Flow</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('insphero_dili')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   
      
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/insphero/tumor.png" class="figure-img img-fluid rounded" alt="InSphero" style="padding: 30px">
                <figcaption class="figure-caption text-center">Tumor Model</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D InSight™ Custom Tumor Microtissues</h5>
                <p class="card-text text-start">InSphero assay-ready 3D InSight™ Tumor Microtissues are designed to simplify and accelerate oncology drug efficacy testing. Using the 3D Select™ Process, our oncology experts have identified optimal culture conditions for large-scale, reproducible production of 3D spheroid models that better reflect complex tumor biology. </p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Leverage physiologically relevant 3D tumor co-cultures composed of tumor and stromal components — with no artificial matrices.</li>
                    <li class="text-start">Assess single agent or combinatorial efficacy of small molecule, biological, ADC, or immunomodulatory drugs using 3D optimized endpoints.</li>
                    <li class="text-start">Quality controlled pre-validated models that optimize your staff resources and ensure robuts, reproducible results.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Oncology</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Tumor</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ 96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ 384-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ Flow</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('insphero_dili')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   
    </div> 

    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/insphero/pancreas.png" class="figure-img img-fluid rounded" alt="InSphero" style="padding: 30px">
                <figcaption class="figure-caption text-center">Pancreas Model</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">3D InSight™ Islet Microtissues</h5>
                <p class="card-text text-start">3D InSight™ Human Islet Microtissues are a standardized alternative to primary human pancreatic islets for reproducible, reliable, and more efficient research. </p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Expand your GSIS assay window and improve sensitivity to insulin secretagogues with a long-lived, robust model persisting more than 28 days in culture.</li>
                    <li class="text-start">Eliminate islet pooling and data normalization with islet microtissues homogeneous in size and cellular composition.</li>
                    <li class="text-start">Increase efficiency 6-fold by eliminating the need to handpick islets with microtissues available bi-monthly and delivered assay-ready in a non-adherent, 96-well plate.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Diabetes</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Endocrine</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Islet</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">β-cell</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">α-cell</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ 96-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ 384-well plate</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Akura™ Flow</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Toxicology Model</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('insphero_dili')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   

      <!-- missing second -->
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      search_query: null,
    };
  },
  methods: {
    redirect_to_exp_builder: function (expid) {
      this.$router.push(this.$route.path + '/design/' + expid);
      // window.location = "/" + expid;
      // alert("Not connected yet. You will be redirected to the experiment builder.");
    },
    search: function () {
      alert('Not connected yet. You will search for [' + this.search_query + ']');
    },
  },
};
</script>

<style scoped>
.bg-custom {
  background-color: rgba(115, 142, 32, 1);
}
</style>

