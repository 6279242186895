<template>
  <div class="container">
    <Navbar />

    <div class="row m-2">
      <div class="col">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <router-link class="breadcrumb-item" to="/">Home</router-link>
            <li class="breadcrumb-item active" aria-current="page">In vitro biology</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 my-3">
      <div class="col mb-3" v-for="(subcategory, index) in subcategories" :key="index">
        <div class="card h-100">
          <h5 class="card-header">{{ subcategory.name }}</h5>
          <div class="card-body">
            <h5 class="card-title">{{ subcategory.description }}</h5>
            <!-- <p class="card-text"> -->
            <!-- <img :src="subcategory.img" :width="subcategory.img_width" class="mt-4 mb-5 pb-2" />
              <br /> -->
            <!-- {{ subcategory.description }} -->
            <!-- </p> -->
            <p class="card-text text-start">
              <span v-for="(assay, index2) in subcategory.assays" :key="index2" class="me-1">
                <a :href="assay.url">
                  <h5>
                    <span class="badge rounded-pill bg-primary">{{ assay.name }}</span>
                  </h5>
                </a>
              </span>
            </p>

            <!-- <a :href="subcategory.link" class="btn btn-primary stretched-link">Visit Portal</a> -->
          </div>
          <div v-if="subcategory.assays && subcategory.assays.length > 0"></div>
          <div v-else class="card-footer">
            <button href="#" class="btn btn-dark btn-sm" type="button" disabled>Coming Soon</button>
            <!-- <a :href="subcategory.link" class="btn btn-primary stretched-link">Explore</a> -->
            <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
export default {
  name: 'Home',
  components: {
    Navbar,
  },
  data() {
    return {
      // Pancreatic-Endocrine Toxicity Assays
      // InSphero
      // CN Bio

      // Blood Toxicity Assays
      // Stemcell Technologies

      // Genotoxicty Assays
      // Creative Bioarray

      // Tumor Assay
      // Cellink
      // InSphero
      // Stemcell Technologies
      // CN Bio

      // Histology Services
      // Creative Bioarray

      // Cell Line Engineering & Testing
      // FujiFilm Cellular Dynamics
      // Creative Bioarray
      // ChemPartner

      // Exosome Research Services
      // Creative Bioarray

      subcategories: [
        {
          name: 'Liver models & assays',
          // img: 'img/insphero/marketplace_logo.png',
          // img_width: 200,
          // link: '/marketplace/chemistry',
          description: '',
          tags: ['InSphero', 'Creative Bioarray', 'CN Bio'],
          assays: [
            {
              name: '3D InSight™ Drug-Induced Liver Injury (DILI) model',
              url: 'https://insphero.benchomatic.com/',
            },
          ],
        },
        {
          name: 'Heart models & assays',
          // img: 'img/insphero/marketplace_logo.png',
          // img_width: 200,
          // link: '/marketplace/chemistry',
          description: '',
          tags: ['Curi Bio', 'Tara Biosystems', 'Creative Bioarray'],
        },

        {
          name: 'Eye models and assays',
          // img: 'img/insphero/marketplace_logo.png',
          // img_width: 200,
          // link: '/marketplace/chemistry',
          description: '',
          tags: ['Creative Biolabs'],
        },

        {
          name: 'Kidney models & assays',
          // img: 'img/insphero/marketplace_logo.png',
          // img_width: 200,
          // link: '/marketplace/chemistry',
          description: '',
          tags: ['Creative Bioarray'],
        },

        {
          name: 'Pancreas-endocrine models & assays',
          // img: 'img/insphero/marketplace_logo.png',
          // img_width: 200,
          // link: '/marketplace/chemistry',
          description: '',
          tags: ['InSphero', 'CN Bio'],
        },

        {
          name: 'Blood models & assays',
          // img: 'img/insphero/marketplace_logo.png',
          // img_width: 200,
          // link: '/marketplace/chemistry',
          description: '',
          tags: ['Stemcell Technologies'],
        },

        {
          name: 'Genotoxicity models & assays',
          // img: 'img/insphero/marketplace_logo.png',
          // img_width: 200,
          // link: '/marketplace/chemistry',
          description: '',
          tags: ['Creative Bioarray'],
        },

        {
          name: 'Tumor models & assays',
          // img: 'img/insphero/marketplace_logo.png',
          // img_width: 200,
          // link: '/marketplace/chemistry',
          description: '',
          tags: ['Cellink', 'InSphero', 'Stemcell Technologies', 'CN Bio'],
        },

        {
          name: 'Histology services',
          // img: 'img/insphero/marketplace_logo.png',
          // img_width: 200,
          // link: '/marketplace/chemistry',
          description: '',
          tags: ['Creative Bioarray'],
        },

        {
          name: 'Cell line engineering & testing',
          // img: 'img/insphero/marketplace_logo.png',
          // img_width: 200,
          // link: '/marketplace/chemistry',
          description: '',
          tags: ['FujiFilm Cellular Dynamics', 'Creative Bioarray', 'ChemPartner'],
        },

        {
          name: 'Exosome research services',
          // img: 'img/insphero/marketplace_logo.png',
          // img_width: 200,
          // link: '/marketplace/chemistry',
          description: '',
          tags: ['Creative Bioarray'],
        },
      ],
    };
  },
};
</script>

<style scoped>
.bg-custom {
  /* background-color: rgba(21, 27, 150, 1); */
  background-color: rgba(238, 131, 0, 1);
}
</style>
