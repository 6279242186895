
let demo_prefix = "cellink";
let experiment_header = "3D Tumor Microtissue Model";
let nodes = [
    { 
    id: 'node-1', 
    title: 'Microtissue Type', 
    icon: ['fas', 'heartbeat'],
    data: [
        {
        type: "select",
        options: [
            {"task": "1", "value": demo_prefix + "-1a", "label": "Liver Microtissues", "cost": 750, "duration": 0},
            {"task": "1", "value": demo_prefix + "-1b",  "label": "Islet Microtissues",  "cost": 850, "duration": 0},
            {"task": "1", "value": demo_prefix + "-1c", "label": "3D Tumor Microtissues", "cost": 650, "duration": 0},
        ],
        }
    ]
    },
    { 
    id: 'node-2', 
    title: 'Area of Focus', 
    icon: ['fas', 'eye'],
    data: [
        {
        type: "select",
        options: [
            {"task": "2", "value": demo_prefix + "-2a", "label": "Drug Screening", "cost": 0, "duration": 0},
            {"task": "2", "value": demo_prefix + "-2b", "label": "Disease Biology", "cost": 0, "duration": 0},
        ],
        }
    ]
    },
    { 
    id: 'node-3', 
    title: 'Prediction Applications', 
    icon: ['fas', 'chart-bar'],
    data: [
        {
        type: "select",
        options: [
            {"task": "3", "value": demo_prefix + "-3a", "label": "Tier 1: Short-Term", "cost": 750, "duration": 7},
            {"task": "3", "value": demo_prefix + "-3b", "label": "Tier 2: Medium-term", "cost": 1500, "duration": 14},
            {"task": "3", "value": demo_prefix + "-3c", "label": "Tier 3: Long-term", "cost": 2500, "duration": 28},
        ],
        }
    ]
    },
    { 
    id: 'node-4', 
    title: 'Cancer Cell Type', 
    icon: ['fas', 'dna'],
    data: [
        {
        type: "select",
        options: [
            {"task": "4", "value": demo_prefix + "-4a", "label": "HepG2", "cost": 6, "duration": 0},
            {"task": "4", "value": demo_prefix + "-4b", "label": "Caco2", "cost": 3, "duration": 0},
            {"task": "4", "value": demo_prefix + "-4c", "label": "BxPC3", "cost": 4, "duration": 0},
            {"task": "4", "value": demo_prefix + "-4d", "label": "RAW 264.7", "cost": 5, "duration": 0},
            {"task": "4", "value": demo_prefix + "-4e", "label": "Panc-1", "cost": 3, "duration": 0},
            {"task": "4", "value": demo_prefix + "-4f", "label": "MCF7", "cost": 5, "duration": 0},
            {"task": "4", "value": demo_prefix + "-4g", "label": "All", "cost": 26, "duration": 0},
        ]
        },
    ]
    },
    { 
    id: 'node-5', 
    title: 'Total Drugs to Screen', 
    icon: ['fas', 'vial'],
    data: [
        {
        type: "select",
        options: [
            {"task": "5", "value": "1", "label": "x1", "cost": 0, "duration": 0},
            {"task": "5", "value": "5", "label": "x5", "cost": 0, "duration": 0},
            {"task": "5", "value": "10", "label": "x10", "cost": 0, "duration": 0},
            {"task": "5", "value": "25", "label": "x25", "cost": 0, "duration": 0},
            {"task": "5", "value": "50", "label": "x50", "cost": 0, "duration": 0},
            {"task": "5", "value": "100", "label": "x100", "cost": 0, "duration": 0},
            {"task": "5", "value": "250", "label": "x250", "cost": 0, "duration": 0},
            {"task": "5", "value": "500", "label": "x500", "cost": 0, "duration": 0},
        ]
        },
    ]
    },
    { 
    id: 'node-6', 
    title: 'Total Microtissues to Screen (per drug)', 
    icon: ['fas', 'th'],
    data: [
        {
        type: "select",
        options: [
            {"task": "6", "value": "3", "label": "x3", "cost": 0, "duration": 0},
            {"task": "6", "value": "4", "label": "x4", "cost": 0, "duration": 0},
            {"task": "6", "value": "5", "label": "x5", "cost": 0, "duration": 0},
            {"task": "6", "value": "10", "label": "x10", "cost": 0, "duration": 0},
            {"task": "6", "value": "15", "label": "x15", "cost": 0, "duration": 0},
        ],
        }
    ]
    },
    { 
    id: 'node-7', 
    title: 'Readouts', 
    icon: ['fas', 'ruler'],
    data: [
        {
        type: "select",
        options: [
            {"task": "7", "value": demo_prefix + "-7a", "label": "1 Readout", "cost": 5, "duration": 0},
            {"task": "7", "value": demo_prefix + "-7b", "label": "2 Readouts", "cost": 10, "duration": 0},
            {"task": "7", "value": demo_prefix + "-7c", "label": "3 Readouts", "cost": 15, "duration": 0},
            {"task": "7", "value": demo_prefix + "-7d", "label": "4 Readouts", "cost": 20, "duration": 0},
            {"task": "7", "value": demo_prefix + "-7e", "label": "5 Readouts", "cost": 25, "duration": 0},
        ],
        }
    ]
    },
    { 
    id: 'node-8', 
    title: 'Microtissue Seeding & Formation', 
    data: [
        {
        type: "select",
        options: [
            {"task": "8", "value": demo_prefix + "-8a", "label": "BIO-X6", "cost": 8000, "duration": 0},
        ]
        },
    ]
    },
    { 
        id: 'node-9', 
        title: 'Drug Dispensing', 
        data: [
            {
            type: "select",
            options: [
                {"task": "9", "value": demo_prefix + "-9a", "label": "I-DOT", "cost": 500, "duration": 0},
            ],
            }
        ]
    },
    { 
        id: 'node-10', 
        title: 'Automated Monitoring & Imaging', 
        data: [
            {
            type: "select",
            options: [
                {"task": "10", "value": demo_prefix + "-10a", "label": "CELLCYTE X", "cost": 7500, "duration": 0},
            ],
            }
        ]
    },
];

let header_cellink_1 = experiment_header;
let nodes_cellink_1 = nodes;

export {header_cellink_1, nodes_cellink_1}
