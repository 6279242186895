<template>
  <nav class="navbar navbar-expand fixed-top navbar-dark bg-cro">
    <div class="container-fluid">
      <a class="navbar-brand" href="#" @click.prevent="redirect_to_cro_home">
        <!-- <img src="/img/logo.png" width="100" alt class="d-inline-block align-top" /> -->
        <!-- Benchomatic Marketplace -->
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="d-flex">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <button class="btn btn-dark" type="submit" @click.prevent="redirect_to_contact()">Contact</button>
          </li>
          <li class="dropdown" v-if="$store.state.user">
            <a
              class="btn btn-dark dropdown-toggle mx-1"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              <i class="fas fa-user-circle"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdown">
              <li>
                <a class="dropdown-item p-3" href="#" @click.prevent="redirect_to_account">My Account</a>
              </li>
              <li>
                <hr class="dropdown-divider my-0" />
              </li>
              <li><a class="dropdown-item p-3" href="#" @click.prevent="sign_out" id="signout-btn">SignOut</a></li>
            </ul>
          </li>
          <!-- <li class="nav-item" v-if="!$store.state.user">
            <button class="btn btn-dark" type="submit" @click.prevent="sign_in">SignIn</button>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    redirect_to_contact: function () {
      var self = this;
      self.$router.push('/contact');
    },
  },
};
</script>
<style>
.bg-cro {
  background-color: #8cb803;
}
</style>
