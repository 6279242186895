import { createRouter, createWebHistory } from 'vue-router';

import MarketplaceHome from '@/views/MarketplaceHome.vue';
import MarketplaceOld from '@/views/MarketplaceOld.vue';
import MarketplaceChemistry from '@/views/MarketplaceChemistry.vue';
import MarketplaceBiologyInVitro from '@/views/MarketplaceBiologyInVitro.vue';
import MarketplaceBiologyInVivo from '@/views/MarketplaceBiologyInVivo.vue';
import MarketplaceComputation from '@/views/MarketplaceComputation.vue';
import Experiment from '@/views/Experiment.vue';
import Auth from '@/views/Auth.vue';
import AccountDemo1 from '@/views/AccountDemo1.vue';
import AccountDemo2 from '@/views/AccountDemo2.vue';
import AccountDemo3 from '@/views/AccountDemo3.vue';
import AccountDemo4 from '@/views/AccountDemo4.vue';
import AccountDemo5 from '@/views/AccountDemo5.vue';
import AccountDemo6 from '@/views/AccountDemo6.vue';
import Contact from '@/views/Contact.vue';

import HomeCellink from '@/views/HomeCellink.vue';
import HomeInSphero from '@/views/HomeInSphero.vue';
import HomeStemCell from '@/views/HomeStemCell.vue';
import HomeCNBio from '@/views/HomeCNBio.vue';
import HomeCytiva from '@/views/HomeCytiva.vue';
import HomeFujifilm from '@/views/HomeFujifilm.vue';
import HomeChimeBiologics from '@/views/HomeChimeBiologics.vue';
import HomeMiLaboratory from '@/views/HomeMiLaboratory.vue';

import HomeGeneric from '@/views/HomeGeneric.vue';

// const landing_pages = {
//   cellink: {
//     name: 'HomeCellink',
//     component: HomeCellink,
//   },
//   insphero: {
//     name: 'HomeInSphero',
//     component: HomeInSphero,
//   },
// }
// console.log("cro id=", process.env.VUE_APP_CRO_ID);
// console.log("path=", this.$route);

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },

  {
    path: '/marketplace',
    name: 'MarketplaceOld',
    component: MarketplaceOld,
  },
  {
    path: '/',
    name: 'MarketplaceHome',
    component: MarketplaceHome,
  },

  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },

  {
    path: '/marketplace/chemistry',
    name: 'MarketplaceChemistry',
    component: MarketplaceChemistry,
  },
  {
    path: '/marketplace/biology-in-vitro',
    name: 'MarketplaceBiologyInVitro',
    component: MarketplaceBiologyInVitro,
  },
  {
    path: '/marketplace/biology-in-vivo',
    name: 'MarketplaceBiologyInVivo',
    component: MarketplaceBiologyInVivo,
  },
  {
    path: '/marketplace/computation',
    name: 'MarketplaceComputation',
    component: MarketplaceComputation,
  },
  {
    path: '/cellink',
    name: 'HomeCellink',
    component: HomeCellink,
  },
  {
    path: '/insphero',
    name: 'HomeInSphero',
    component: HomeInSphero,
  },
  {
    path: '/stemcell',
    name: 'HomeStemCell',
    component: HomeStemCell,
  },
  {
    path: '/cnbio',
    name: 'HomeCNBio',
    component: HomeCNBio,
  },
  {
    path: '/cytiva',
    name: 'HomeCytiva',
    component: HomeCytiva,
  },
  {
    path: '/exampleco',
    name: 'HomeGeneric',
    component: HomeGeneric,
  },
  {
    path: '/fujifilm',
    name: 'HomeFujifilm',
    component: HomeFujifilm,
  },
  {
    path: '/chimebiologics',
    name: 'HomeChimeBiologics',
    component: HomeChimeBiologics,
  },
  {
    path: '/milaboratory',
    name: 'HomeMiLaboratory',
    component: HomeMiLaboratory,
  },

  // {
  //   path: '/',
  //   name: landing_pages[process.env.VUE_APP_CRO_ID]['name'],
  //   component: landing_pages[process.env.VUE_APP_CRO_ID]['component']
  // },
  {
    path: '/:cro_id/design/:experiment_id',
    name: 'Experiment',
    component: Experiment,
  },

  {
    path: '/:cro_id/account/1001/step1',
    name: 'AccountDemo1',
    component: AccountDemo1,
  },

  {
    path: '/:cro_id/account/1001/step2',
    name: 'AccountDemo2',
    component: AccountDemo2,
  },
  {
    path: '/:cro_id/account/1001/step3',
    name: 'AccountDemo3',
    component: AccountDemo3,
  },
  {
    path: '/:cro_id/account/1001/step4',
    name: 'AccountDemo4',
    component: AccountDemo4,
  },
  {
    path: '/:cro_id/account/1001/step5',
    name: 'AccountDemo5',
    component: AccountDemo5,
  },
  {
    path: '/:cro_id/account/1001/step6',
    name: 'AccountDemo6',
    component: AccountDemo6,
  },

  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth.vue'),
  },
  // {
  //   path: "/account",
  //   name: "Account",
  //   component: Account
  // },
  // {
  //   path: "/orders",
  //   name: "Orders",
  //   component: Orders
  // },
  // {
  //   path: "/orders/:order_id",
  //   name: "Order",
  //   component: Order
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // mode: "history",
  routes,
});

export default router;
