import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// to be parametrized with .env file
// const config = {
//   apiKey: "AIzaSyAvBLIfB38CzVBITmtFNPOddkhjUeiGDEw",
//   authDomain: "benchomatic-739b4.firebaseapp.com",
//   projectId: "benchomatic-739b4",
//   storageBucket: "benchomatic-739b4.appspot.com",
//   messagingSenderId: "488008966179",
//   appId: "1:488008966179:web:357c8a8a94f4a36c28d196",
//   measurementId: "G-054L61MRY6"
// };

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: 'AIzaSyBp1y4G8JSInBmlch7Q5nEniI7G-4g62_g',
  authDomain: 'benchomatic-beta.firebaseapp.com',
  databaseURL: 'https://benchomatic-beta-default-rtdb.firebaseio.com',
  projectId: 'benchomatic-beta',
  storageBucket: 'benchomatic-beta.appspot.com',
  messagingSenderId: '498261212836',
  appId: '1:498261212836:web:5334937033e9b0efde7031',
  measurementId: 'G-TB1NP1C35S',
};

firebase.initializeApp(config);

const auth = firebase.auth();
const db = firebase.database();

export { firebase, auth, db };
