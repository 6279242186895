<template>
  <div>
    
    <!-- <nav class="navbar fixed-top navbar-light" style="background-color: #8cb802;"> -->
    <nav class="navbar fixed-top navbar-light bg-nav">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="img/cnbio/logo.png" width="200" alt="" class="d-inline-block align-top"></a>
        <form class="d-flex">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"  size="50"
            v-model="search_query">
          <button class="btn btn-success bg-custom" type="submit"
            @click.prevent="search">Search</button>
        </form>
      </div>
    </nav>


    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            Featured
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/cnbio/liver.png" class="figure-img img-fluid rounded" alt="CNBio" style="padding: 30px">
                <figcaption class="figure-caption text-center">Liver Model</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">PhysioMimix™ OOC NAFLD/NASH Model</h5>
                <p class="card-text text-start">The PhysioMimix™ OOC NAFLD/NASH Model consists of an advanced microtissue co-culture of healthy primary human liver cells combined with specially formulated media and cocktails that induce the progression of NAFLD and NASH from steatosis to liver inflammation and fibrosis. Ideal for investigating mechanisms of non-alcoholic steatohepatitis (NASH) induction, in vitro screening of anti-NASH drug efficacy, and safety assessment of drug candidates in a relevant disease background, this powerful model includes all the critical liver cells and inducers needed to recapitulate the progression of human fatty liver disease.</p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Rely on a physiologically relevant model composed of primary human hepatocytes (PHHs), hepatic stellate cells (HSCs), Kupffer cells, and liver endothelial cells (LECs). </li>
                    <li class="text-start">Expand your assay window by leveraging a pre-qualified model that exhibits pro-inflammatory marker induction reflecting the in vivo disease state: MCP-1/CCL2, TNF-α, MIP-1α/CCL3, IL-8, IL-6, and IP-10.</li>
                    <li class="text-start">Perform reproducible, throughput-compatible NASH drug screening using a scalable Akura™ plate technology, amenable to a host of certified application endpoints.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Liver</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">NAFLD</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">NASH</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">PhysioMimix™ OOC</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Drug Screening</span>&nbsp;
                </p>
                <!-- <p class="text-center">
                  <a href="#" class="btn btn-primary" 
                    @click.prevent="redirect_to_exp_builder('physio-mimix-model-liver-w5ak74')"
                    >Order Now</a>
                </p> -->
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('generic_2')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div> 

      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/cnbio/tumor.png" class="figure-img img-fluid rounded" alt="CNBio" style="padding: 30px">
                <figcaption class="figure-caption text-center">Tumor Model</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">PhysioMimix™ OOC Assay-Ready Tumor Microtissues</h5>
                <p class="card-text text-start">PhysioMimix™ OOC Tumor Microtissues are designed to simplify and accelerate oncology drug efficacy testing. Our oncology experts have identified optimal culture conditions for large-scale, reproducible production of 3D spheroid models that better reflect complex tumor biology. </p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Leverage physiologically relevant 3D tumor co-cultures composed of tumor and stromal components — with no artificial matrices.</li>
                    <li class="text-start">Assess single agent or combinatorial efficacy of small molecule, biological, ADC, or immunomodulatory drugs using 3D optimized endpoints.</li>
                    <li class="text-start">Quality controlled pre-validated models that optimize your staff resources and ensure robust, reproducible results.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Oncology</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Tumor</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">PhysioMimix™ OOC</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('cnbio_dili')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   
    </div> 


    <div class="row m-5">
      <div class="col-lg-6">

        <div class="card mb-3">
          <div class="card-header text-center">
            &nbsp;
          </div>
          <div class="row g-0">
            <div class="col-md-4 d-flex align-items-center">
              <figure class="figure">
                <img src="img/cnbio/pancreas.png" class="figure-img img-fluid rounded" alt="CNBio" style="padding: 30px">
                <figcaption class="figure-caption text-center">Pancreas Model</figcaption>
              </figure>
            </div>
            <div class="col-md-8  float-left">
              <div class="card-body ">
                <h5 class="card-title">PhysioMimix™ OOC Islet Microtissues</h5>
                <p class="card-text text-start">PhysioMimix™ OOC Human Islet Microtissues are a standardized alternative to primary human pancreatic islets for reproducible, reliable, and more efficient research.</p>
                <p class="card-text text-start">
                  <ul>
                    <li class="text-start">Expand your GSIS assay window and improve sensitivity to insulin secretagogues with a long-lived, robust model persisting more than 28 days in culture.</li>
                    <li class="text-start">Eliminate islet pooling and data normalization with islet microtissues homogeneous in size and cellular composition.</li>
                    <li class="text-start">Increase efficiency 6-fold by eliminating the need to handpick islets with microtissues available bi-monthly and delivered assay-ready in a non-adherent, 96-well plate.</li>
                  </ul>  
                  <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
                </p>
                <p class="card-text text-start">
                  <span class="badge rounded-pill bg-custom">Diabetes</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Endocrine</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Islet</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">β-cell</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">α-cell</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">PhysioMimix™ OOC</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Disease Model</span>&nbsp;
                  <span class="badge rounded-pill bg-custom">Toxicology Model</span>&nbsp;
                </p>
                <p class="text-center">
                  <button href="#" class="btn btn-dark" type="button" disabled
                    @click.prevent="redirect_to_exp_builder('cnbio_dili')"
                    >Coming Soon</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>   

      <!-- missing second -->
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      search_query: null,
    };
  },
  methods: {
    redirect_to_exp_builder: function (expid) {
      this.$router.push(this.$route.path + '/design/' + expid);
      // window.location = "/" + expid;
      // alert("Not connected yet. You will be redirected to the experiment builder.");
    },
    search: function () {
      alert('Not connected yet. You will search for [' + this.search_query + ']');
    },
  },
};
</script>

<style scoped>
.bg-nav {
  background-color: rgba(247, 247, 245, 1);
}
.bg-custom {
  background-color: rgba(129, 207, 208, 1);
}
</style>

