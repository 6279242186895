function getCookies(showpopup) {
  var c = document.cookie.split(';');
  var out = {};
  for (var i = 0; i < c.length; i++) {
    var cparts = c[i].split('=');
    out[cparts[0].trim()] = decodeURIComponent(cparts[1]);
  }
  if (showpopup) {
    alert(out);
  }
  return out;
}

function setCookie(cookieName, cookieValue, cookieExpiry) {
  if (window.location.href.indexOf('localhost') > -1) {
    console.log('Setting cookie with no domain arg, we are on localhost..');
    document.cookie = cookieName + '=' + cookieValue + '; expires=' + cookieExpiry + '; path=/';
  } else {
    document.cookie = cookieName + '=' + cookieValue + '; expires=' + cookieExpiry + '; path=/; domain=benchomatic.com';
  }
}

function geturlparamvalue(name) {
  var local_name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regexS = '[\\?&]' + local_name + '=([^&#]*)';
  var regex = new RegExp(regexS);
  var results = regex.exec(window.location.href);
  if (results != null) {
    return [true, results[1]];
  } else {
    return [false, ''];
  }
}

function appAlertBuilder(alert_html, alert_type) {
  if (alert_type === undefined) {
    alert_type = 'info';
  }
  return '<div class="alert alert-' + alert_type + '" role="alert">' + alert_html + '</div>';
}

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
}

export { getCookies, setCookie, geturlparamvalue, appAlertBuilder, uuidv4 };
